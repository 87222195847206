var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      staticClass: "add-flow-modal",
      attrs: { centered: "", show: _vm.show, title: _vm.title },
      on: {
        "update:show": function ($event) {
          return _vm.$emit("update:show", $event)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer-wrapper",
          fn: function () {
            return [_c("span")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "CForm",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.saveFlow.apply(null, arguments)
            },
          },
        },
        [
          _c("CInput", {
            attrs: {
              value: _vm.flowName,
              placeholder: `${_vm.$t(
                "lk.training.flowTable.flowNameInputPlaceholder"
              )}`,
              label: `${_vm.$t("lk.videoEncrypt.EditModal.name")}:`,
              "invalid-feedback": _vm.errors.flowName,
              "is-valid": _vm.detectErrorField(_vm.errors.flowName),
            },
            on: { input: _vm.changeFlowName },
          }),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("CInput", {
                staticClass: "mr-3",
                attrs: {
                  label: _vm.$t("lk.training.flowTable.dateInputLabel"),
                  placeholder: _vm.$t("lk.training.start_at"),
                  type: "datetime-local",
                  value: _vm.startAt,
                },
                on: {
                  input: function ($event) {
                    _vm.startAt = $event
                  },
                },
              }),
              _c("CInput", {
                staticClass: "align-self-end",
                attrs: {
                  placeholder: _vm.$t("lk.training.finish_at"),
                  type: "datetime-local",
                  value: _vm.finishAt,
                },
                on: {
                  input: function ($event) {
                    _vm.finishAt = $event
                  },
                },
              }),
            ],
            1
          ),
          _c("label", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("lk.training.flowTable.programInputLabel")) +
                ": "
            ),
          ]),
          _c("v-select-input", {
            staticClass: "mb-0",
            attrs: {
              placeholder: _vm.$t(
                "lk.training.flowTable.programInputPlacholder"
              ),
              disabled: !!_vm.event,
              options: _vm.eventList,
            },
            scopedSlots: _vm._u([
              {
                key: "no-options",
                fn: function () {
                  return [
                    _vm._v(" " + _vm._s(_vm.$t("general.emptySearch")) + " "),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.event,
              callback: function ($$v) {
                _vm.event = $$v
              },
              expression: "event",
            },
          }),
          _c(
            "div",
            {
              staticClass:
                "add-flow-modal__button-wrapper d-flex w-100 mt-4 flex-wrap",
            },
            [
              _c(
                "CLoadingButtonCustom",
                {
                  staticClass: "mr-3 flex-grow-1",
                  attrs: {
                    color: "success",
                    loading: _vm.loading,
                    disabled: _vm.loading,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.saveFlow.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("general.save")) + " ")]
              ),
              _c(
                "CButton",
                {
                  staticClass: "flex-grow-1",
                  attrs: { color: "dark" },
                  on: { click: _vm.close },
                },
                [_vm._v(" " + _vm._s(_vm.$t("general.cancel")) + " ")]
              ),
              _vm.currentFlow
                ? _c(
                    "CLoadingButtonCustom",
                    {
                      staticClass: "w-100 mt-3",
                      attrs: {
                        color: "danger",
                        loading: _vm.loading,
                        disabled: _vm.loading,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.deleteflow.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("general.delete")) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }