var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "curatorTable" },
    [
      _c("CuratorModal", {
        attrs: {
          show: _vm.showCuratorModal,
          curator: _vm.curatorToUpdate,
          eventId: _vm.eventId,
        },
        on: {
          close: function ($event) {
            _vm.showCuratorModal = false
          },
          success: _vm.handleUpdateSuccess,
          "update:show": function ($event) {
            _vm.showCuratorModal = $event
          },
        },
      }),
      _c("DeleteCuratorModal", {
        attrs: {
          show: _vm.showDeleteCuratorModal,
          curator: _vm.curatorToDelete,
        },
        on: {
          close: function ($event) {
            _vm.showDeleteCuratorModal = false
          },
          success: _vm.handleDeleteSuccess,
          "update:show": function ($event) {
            _vm.showDeleteCuratorModal = $event
          },
        },
      }),
      _c(
        "div",
        { staticClass: "curatorTable__header" },
        [
          _c(
            "span",
            { staticClass: "mb-0 mr-3", attrs: { color: "base-card-bg" } },
            [
              _c("span", { staticClass: "curatorTable__header__white" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$tc("lk.curatorTable.view", _vm.tableDataLength)
                    ) +
                    " "
                ),
              ]),
              _vm._v(
                " " +
                  _vm._s(_vm.tableDataLength) +
                  " " +
                  _vm._s(
                    _vm.$tc("lk.curatorTable.curators", _vm.tableDataLength)
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "CLoadingButtonCustom",
            {
              staticClass: "ml-auto curatorTable__header-btn",
              attrs: { color: "primary" },
              nativeOn: {
                click: function ($event) {
                  return _vm.spreadCurators.apply(null, arguments)
                },
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("lk.curatorTable.spreadCurators")) + " "
              ),
            ]
          ),
          _c(
            "CLoadingButtonCustom",
            {
              staticClass: "ml-auto curatorTable__header-btn",
              attrs: { color: "primary" },
              nativeOn: {
                click: function ($event) {
                  _vm.curatorToUpdate = {}
                  _vm.showCuratorModal = true
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lk.curatorTable.addCurator")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "CCard",
        {
          staticClass: "shadow-none curatorTable__card",
          attrs: { color: "second-card-bg" },
        },
        [
          _c("CCardBody", { staticClass: "h-100 d-flex flex-column" }, [
            _c(
              "div",
              { staticClass: "table-wrap" },
              [
                _c(
                  "table",
                  { staticClass: "table mb-0 curatorTable__table" },
                  [
                    _c("thead", [
                      _c(
                        "tr",
                        [
                          _c("th"),
                          _vm._l(_vm.fields, function (field, i) {
                            return _c("th", { key: i, class: field._classes }, [
                              _vm._v(" " + _vm._s(field.label) + " "),
                            ])
                          }),
                        ],
                        2
                      ),
                    ]),
                    _vm._l(_vm.tableData, function (item, i) {
                      return _c(
                        "tbody",
                        { key: item.id, attrs: { color: "primary" } },
                        [
                          _c("tr", [
                            _c("td", [
                              item.event_users?.length
                                ? _c(
                                    "span",
                                    {
                                      class:
                                        _vm.openedRow == i
                                          ? "curatorTable__table__btn __active"
                                          : "curatorTable__table__btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.openMoreInfo(i)
                                        },
                                      },
                                    },
                                    [_vm._v("❭")]
                                  )
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm._f("formatDate")(item.created_at))
                              ),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(item.user ? item.user.email : "-")),
                            ]),
                            _c("td", [_vm._v(_vm._s(item.name))]),
                            _c(
                              "td",
                              [
                                item.user.tg_nick
                                  ? [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "text-primary",
                                          attrs: {
                                            href: _vm.renderTgLink(
                                              item.user.tg_nick
                                            ),
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " @" +
                                              _vm._s(item.user.tg_nick) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _c("td", [
                              _vm._v(_vm._s(item.event_users?.length)),
                            ]),
                            _c(
                              "td",
                              [
                                _c(
                                  "CButtonGroup",
                                  [
                                    _c(
                                      "CButton",
                                      {
                                        attrs: {
                                          color: "info",
                                          size: "sm",
                                          to: {
                                            name: "edit-curator-author",
                                            params: {
                                              curator_id: item.id,
                                              back: _vm.$route,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _c("CIcon", {
                                          attrs: { name: "cilPencil" },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "CButton",
                                      {
                                        attrs: { color: "danger", size: "sm" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDeleteBtnClick(
                                              item.id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("CIcon", {
                                          attrs: { name: "cilDelete" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("tr", [
                            _vm.openedRow === i
                              ? _c(
                                  "td",
                                  {
                                    staticClass:
                                      "curatorTable__table__td-subTable",
                                    attrs: { colspan: "7" },
                                  },
                                  [
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "table mb-0 curatorTable__table__subTable",
                                      },
                                      [
                                        _c("thead", [
                                          _c(
                                            "tr",
                                            _vm._l(
                                              _vm.fieldsSubTable,
                                              function (subField, i) {
                                                return _c(
                                                  "th",
                                                  { key: "subField" + i },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(subField.label) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]),
                                        _vm._l(
                                          item.event_users,
                                          function (subItem) {
                                            return _c(
                                              "tbody",
                                              { key: subItem.id },
                                              [
                                                _c("tr", [
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(subItem.user.name)
                                                    ),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(subItem.user.email)
                                                    ),
                                                  ]),
                                                  _c("td", [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          target: "_blank",
                                                          href: `https://t.me/${subItem.user.tg_nick}`,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            subItem.user.tg_nick
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        subItem.tariff?.name[
                                                          _vm.$store.state
                                                            .settings.global
                                                            .locale
                                                        ]
                                                      )
                                                    ),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        subItem
                                                          ?.education_flows?.[0]
                                                          ?.flow?.name
                                                      )
                                                    ),
                                                  ]),
                                                  _c("td", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "studetTable__hasMentorshipIcon",
                                                      },
                                                      [
                                                        subItem.has_mentor
                                                          ? _c("CIcon", {
                                                              attrs: {
                                                                name: "check",
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _vm.isLoading ? _c("CElementCover") : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }