<template>
  <div v-if="!video.primary " class="additionalMaterialCard pt-2">
    <div class="additionalMaterialCard__block justify-content-between">
      <div class="additionalMaterialCard__block-right">
        <div class="additionalMaterialCard__block-right-title d-flex align-items-center mb-2">
          <span class="mr-3">
            Видео {{primaryVideo ? index : index + 1}}
          </span>
          <span @click="$emit('removeVideoField', index)">
            <CIcon width="1.25rem" class="additionalMaterialCard__block-right-deleteIcon" name="cilDelete"></CIcon>
          </span>
        </div>
        <div class="additionalMaterialCard__block-right-selectVideo">
          <div class="d-flex flex-column">
            <div class="d-flex align-items-center">
              <CLoadingButton
                class="mr-2"
                color="success"
                @click.native="$emit('openVideoList', primaryVideo ? index + 1 : index + 2)"
              >
                {{ $t(video.filename ? 'lk.videoEncrypt.replaceVideo' : 'lk.videoEncrypt.chooseVideo') }}
              </CLoadingButton>
              <div v-if="video.id">
                <span>
                  {{$t('lk.videoEncrypt.selectedVideo')}}:
                </span>
                <span>
                  {{video.filename}}
                </span>
                <span @click="$emit('removeVideo', index)">
                  <CIcon  width="0.7rem" name="cil-x" class="additionalMaterialCard__block-right-deleteIcon"/>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="additionalMaterialCard__block-left">
        <CInput
          :label="$t('lk.videoEncrypt.title')"
          v-model="video.name"
          type="text"
          class="w-100 mb-0"
        />
      </div>
    </div>
    <div class="additionalMaterialCard__block pb-3">
      <tinymce
        v-model="video.description"
        :label="$t('lk.videoEncrypt.table.description')"
        class="mb-0 w-100"
      />
    </div>
    <div v-if="video.filename" class="additionalMaterialCard__video-time-code-wrapper mt-3">
      <div class="additionalMaterialCard__video-time-code-block d-flex align-items-center">
        <CTextarea
        :label="$t('lk.videoEncrypt.timeCodes')"
        rows="5"
        class="mb-0 w-100"
        v-model="markers"
      />
      </div>
    </div>
  </div>
</template>

<script>
import TimeCodeItem from '@/components/training/TimeCodeItem'
import { secToHHMMSS} from '@/assets/js/timeCode'
import Tinymce from "@/components/common/Tinymce";

export default {
  name: 'AdditionalMaterialCard',
  components: {
    TimeCodeItem,
    Tinymce
  },
  props: {
    video:{
      type: Object,
    },
    index:{
      type: Number
    },
    primaryVideo:{
      type:[Object, null]
    },
  },
  data () {
    return {

    };
  },
  computed: {
    markers: {
      get() {
        let formatedData = ''
        if(this.video.markers && this.video.markers.length){
          this.video.markers.forEach((item)=>{
          formatedData += `${secToHHMMSS(item.time)} - ${item.text}\n`
          })
        }
        this.video.stringFormatMarkers = formatedData
        return formatedData
      },
      set(value) {
        this.video.stringFormatMarkers = value
      }
    },
  },
  watch: {
  },
  beforeMount () {
  },
  methods: {
  },
  watch:{
    video: {
      handler(newVal) {
        if(newVal.filename){
            if(!newVal.markers){
              this.video.markers = []
          }
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.additionalMaterialCard{
  &__block{
    @include media-breakpoint-up(md) {
      display: flex;
    }
    &:last-child{
      border-bottom: 1px solid var(--sub-btn);
    }
    &-right{
      width: 100%;
      margin-bottom: 1rem;
      @include media-breakpoint-up(md) {
        width: 50%;
      }
      &-deleteIcon{
        cursor: pointer;
        color: var(--danger);
        &:hover{
          color: var(--sub-btn);
        }
      }
    }

    &-left{
      width: 100%;
      margin-bottom: 1rem;
      @include media-breakpoint-up(md) {
        width: 50%;
      }
    }

    &:last-child{
      flex-direction: column;
    }
    &-textarea{
      height: 160px;
    }
  }
  &:last-child{
      border: none;
  }

  &__video-time-code{
      &-add-button{
      display: flex;
      align-items: flex-end;
      justify-content: center;
      background: var(--primary);
      border-radius: 50%;
      width: 20px;
      height: 20px;
      padding: 0;
      cursor: pointer;
      &:hover{
        background: var(--sub-btn);
      }
    }
  }
}
</style>

