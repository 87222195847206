<template>
  <CModal
  centered
  :show="show"
  @update:show="$emit('update:show', $event)"
  class="addCuratorModal">
    <template #header-wrapper>
      <div class="addCuratorModal__header">
        <span>{{ $t('lk.training.editTariff.modalTitle') }}</span>
        <div @click="close" class="addCuratorModal__header-close">×</div>
      </div>
    </template>

    <template #body-wrapper>
      <div class="addCuratorModal__body">
        <CSelect
          :value.sync="selectedTariff"
          :label="`${$t('lk.permission.subscription')}:`"
          :placeholder="$t('lk.permission.chooseAPlan')"
          :options="tariffList"
        />

        <div class="addCuratorModal__btnBlock">
          <CButton
            @click="saveTariff"
            :disabled="!isTariffChanged || isLoading"
            class="shadow-none addCuratorModal__btn"
            color="success"
          >
            {{ $t('general.save') }}
          </CButton>
          <CButton class="shadow-none addCuratorModal__btn" color="primary" @click="close">
            {{ $t('general.cancel') }}
          </CButton>
        </div>
      </div>
      <CElementCover v-if="isLoading" />
    </template>

    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>
import axios from 'axios'

export default {
  name: 'EditTariffModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    eventId: {
      type: Number,
      required: true,
    },
    currentStudent: {
      type: [Object, null],
      required: null,
    },
  },

  data() {
    return {
      tariffList:[
        {
          label: 'WEB3',
          value: 'web3_edu'
        },
        {
          label: 'Crypto Basics',
          value: 'crypto_basics_edu'
        },
        {
          label: 'STANDART',
          value: 'standard_edu'
        },
        {
          label: 'ALL-IN',
          value: 'all_in_edu'
        },
      ],
      selectedTariff: this.currentStudent.tariff.id || null,
      isLoading: false,
    }
  },

  computed:{
    isTariffChanged(){
      return this.selectedTariff !== this.currentStudent.tariff.id
    }
  },

  methods: {
    saveTariff() {
      if (!this.isLoading && this.isTariffChanged) {
        this.isLoading = true
        axios
          .post(`/api/v2/events/${this.eventId}/users`, {
            id: [this.currentStudent.id],
            tariff: this.selectedTariff
          })
          .then(() => {
            this.$toastr.success(this.$t('lk.training.editTariffFinishDate.changesSaved'))
            this.$emit('success')
            this.close()
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.ccard {
  margin-bottom: 0;
  max-height: calc(100vh - 300px);
  max-height: calc(100dvh - 300px);

  @include media-breakpoint-down(xs) {
    .ccard-body {
      padding: 1rem 1.75rem;
    }
  }

  @include media-breakpoint-up(sm) {
    .ccard-body {
      padding: 1.25rem 1.75rem;
    }
  }

  @include media-breakpoint-up(xl) {
    margin: 0;
  }
}

.addCuratorModal {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 0;
    font-size: 21px;
    line-height: 1;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      padding: 24px 32px 0;
    }

    &-close {
      font-size: 23px;
      font-weight: 100;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $danger;
      }
    }
  }

  &__btn {
    width: 100%;
    height: 44px;
  }

  &__btnBlock {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: 20px;
    }
  }

  &__body {
    padding: 20px 16px 20px;

    @include media-breakpoint-up(md) {
      padding: 24px 32px 32px;
    }
  }
}
</style>
