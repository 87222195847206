<template>
  <CModal
  centered
  :show="show"
  :closeOnBackdrop="false"
  @update:show="$emit('update:show', $event)"
  class="add-student-modal"
  :title="$t('lk.training.flowTable.addStudents')">
    <CForm @submit.prevent="saveStudents">
        <label>
          {{ $t('lk.training.flowTable.studentsListLabel') }}:
        </label>
        <v-select-input
          class="mb-0 students-select"
          :placeholder="$t('lk.curator.student')"
          v-model="selectedStudents"
          :options="studentsList"
          multiple
        >
          <template #no-options>
            {{ $t('general.emptySearch') }}
          </template>
        </v-select-input>
      <div class="add-flow-modal__button-wrapper d-flex w-100 mt-4">
        <CLoadingButtonCustom
          class="mr-3 flex-grow-1"
          color="success"
          :loading="loading"
          :disabled="loading"
          @click.native="saveStudents"
        >
          {{ $t('general.save') }}
        </CLoadingButtonCustom>
        <CButton
          class="flex-grow-1"
          color="dark"
          @click="close"
        >
          {{ $t('general.cancel') }}
        </CButton>
      </div>
    </CForm>
    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>
import VSelectInput from "@/components/VSelectInput";
import axios from 'axios'

export default {
  name: 'AddStudentModal',
  components: {
    VSelectInput,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    currentFlow: {
      type: [Object, null],
      default: null
    },
  },
  data() {
    return {
      loading: false,
      selectedStudents: [],
      studentsList: [],
      errors: {
        flowName: null
      }
    }
  },
  async beforeMount(){
    await this.getStdents()
  },
  methods: {
    async saveStudents(){
      try {
        this.loading = true
        const url = `/api/v2/education-flows/${this.currentFlow.id}/add-users`
        const user_ids = this.selectedStudents.map(item => item.value)
        const {data} = await axios.post(url, {
          user_ids
        })
        if(data.status === 'error'){
          this.$toastr.error(this.$t(data.message));
        }else{
          this.$toastr.success(this.$t("general.dataSaved"));
          this.$emit('updateFlows')
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.close()
        this.loading = false
      }
    },
    async getStdents(){
      try {
        this.studentsList = []
        const { data } = await axios.get(`/api/v1/users/flow`,{
          params:{
            education_flow_id: this.currentFlow.id
          }
        })
        if (data?.data) {
          const idIndex = []
          data.data.forEach(item => {
            if(idIndex.includes(item.id)){
              return
            }
            idIndex.push(item.id)
            this.studentsList.push({
              value: item.id,
              label: item.email
            })
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.students-select{
  &:deep(.vs__selected) {
    background: var(--primary);
    padding: 0 5px;
  }
}
.add-flow-modal {

  &__button-wrapper{
    height: 50px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 0;
    font-size: 21px;
    line-height: 1;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      padding: 24px 32px 0;
    }

    &-close {
      font-size: 23px;
      font-weight: 100;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $danger;
      }
    }
  }

  &__btn {
    width: 100%;
    height: 44px;
  }

  &__btnBlock {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: 20px;
    }
  }

  &__body {
    padding: 20px 16px 20px;

    @include media-breakpoint-up(md) {
      padding: 24px 32px 32px;
    }
  }
}
</style>
