<template>
  <div class="w-100">
    <CSpinner v-if="loading" color="primary" size="lg" />
    <div class="trainingMaterials" v-else>
      <CCard
        color="second-card-bg"
        class="trainingMaterials__block"
      >
        <CInput
          :label="$t('lk.training.nameTraining')"
          type="text"
          class="pl-0"
          v-model="course.course_title"
        />
        <CInput
          :label="$t('lk.training.slugTraining')"
          type="text"
          class="pl-0"
          v-model="course.slug"
        />
        <div class="row">
          <div class="col-lg-6">
            <label>{{ $t('lk.training.availableToBuyFrom') }}</label>
            <v-date-picker
              mode="dateTime"
              v-model="course.availableToBuyFrom"
              :is-dark="$store.getters.darkMode"
              is24hr
              :masks="{input: 'DD.MM.YYYY HH:mm'}"
              :locale="$root.$i18n.locale"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <CInput
                  :value="inputValue"
                  :placeholder="$t('trends.datePlaceholder')"
                  readonly
                  class="input"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>

            <label>{{ $t('lk.training.availableForTrainingFrom') }}</label>
            <v-date-picker
              mode="dateTime"
              v-model="course.availableForTrainingFrom"
              :is-dark="$store.getters.darkMode"
              is24hr
              :masks="{input: 'DD.MM.YYYY HH:mm'}"
              :locale="$root.$i18n.locale"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <CInput
                  :value="inputValue"
                  :placeholder="$t('trends.datePlaceholder')"
                  readonly
                  class="input"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </div>
          <div class="col-lg-6 mb-3">
            <CButtonGroup class="form-group trainingMaterials__header__btns w-100">
              <CButton
                :color="course.published ? 'success' : 'dark'"
                @click="course.published = true"
                class="trainingMaterials__header__btn"
              >
                {{ $t("lk.training.active") }}
              </CButton>
              <CButton
                :color="!course.published ? 'danger' : 'dark'"
                @click="course.published = false"
                class="trainingMaterials__header__btn"
              >
                {{ $t("lk.training.notActive") }}
              </CButton>
            </CButtonGroup>

            <div class="form-group">
              <label>{{ $t('lk.training.accessFlows') }}</label>
              <v-select
                ref="vselect"
                :closeOnSelect="false"
                v-model="course.accessFlows"
                :loading="loading"
                :options="educationFlows"
                :filter="flowsCustomFilter"
                :getOptionKey="(option) => option?.id"
                :getOptionLabel="(option) => getFlowsOptionName(option)"
                class="v-select-flows"
                multiple
                :placeholder="accessInitType === 'all' ? $t('lk.training.accessFlowsAll') : $t('lk.training.accessFlowsFlows')"
              >
                <template v-slot:option="option">
                  <div class="rounded-pill">
                    <span class="badge">
                    {{ getFlowsOptionName(option) }}
                    </span>
                  </div>
                </template>
                <!-- <template #selected-option-container="{ option, deselect, multiple, disabled }">
                  <span class="vs__selected badge">
                    {{ getFlowsOptionName(option) }}
                    <button
                      v-if="multiple"
                      :disabled="disabled"
                      @click="deselect(option)"
                      type="button"
                      class="vs__deselect"
                      :title="`${$t('general.deselect')} ${getFlowsOptionName(option)}`"
                      :aria-label="`${$t('general.deselect')} ${getFlowsOptionName(option)}`"
                    >
                    </button>
                  </span>
                </template> -->
              </v-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-5 col-xl-4">
            <CInputFile
              :label="$t('lk.training.coverPhoto')"
              accept=".jpg, .jpeg, .png"
              type="file"
              @change="onChangeInputFile('course_cover_photo', $event)"
            />
            <div class="trainingMaterials__img mb-4" v-if="course.course_cover_photo">
              <img
                :src="`${baseUrl}${course.course_cover_photo}`"
                alt=""
              >
            </div>
            <CInputFile
              :label="$t('lk.training.widePhoto')"
              accept=".jpg, .jpeg, .png"
              type="file"
              @change="onChangeInputFile('course_wide_photo', $event)"
            />
            <div class="trainingMaterials__img mb-4" v-if="course.course_wide_photo">
              <img
                :src="`${baseUrl}${course.course_wide_photo}`"
                alt=""
              >
            </div>
          </div>
          <div class="col-lg-7 col-xl-8">
            <h3>{{ $t('lk.training.usefulLinks.header') }}</h3>
            <div class="row" v-for="(linkItem, linkItemI) in course.links">
              <div class="col-sm">
                <CInput
                  :label="$t('lk.training.usefulLinks.inputTitle')"
                  type="text"
                  v-model="linkItem.title"
                />
              </div>
              <div class="col-sm d-flex align-items-center">
                <CInput
                  :label="$t('lk.training.usefulLinks.inputLink')"
                  type="text"
                  class="flex-grow-1"
                  v-model="linkItem.link"
                />
                <CButton
                  class="btn-sm shadow-none inline ml-3"
                  style="margin-top: 12px"
                  color="outline-danger"
                  @click="course.links.splice(linkItemI, 1)"
                >
                  <CIcon name="cil-x" />
                </CButton>
              </div>
            </div>
            <CButton
              color="success"
              @click="course.links.push({title: '', link: ''})"
            >
              {{ $t("lk.training.usefulLinks.add") }}
            </CButton>
          </div>
        </div>
        <tinymce
          v-model="course.course_description"
          :label="$t('lk.training.descTraining')"
          class="mb-0 w-100"
        />
        <CLoadingButtonCustom
          color="primary"
          class="cbtn mt-4 trainingMaterials__btn"
          :loading="loadBtn"
          :disabled="loadBtn"
          @click.native="editPreviewData"
        >
          {{ $t('lk.training.btnSaveCourse') }}
        </CLoadingButtonCustom>
      </CCard>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { DatePicker } from 'v-calendar'
import moment from 'moment'
import Tinymce from "@/components/common/Tinymce"
import VSelect from 'vue-select'
import Author from '@/assets/js/Author.class'

export default {
  name: 'TrainingPreview',
  components: {
    'v-date-picker': DatePicker,
    Tinymce,
    VSelect,
  },
  props: {
    eventId: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      baseUrl: axios.defaults.baseURL,
      loading: false,
      loadBtn: false,
      showModal: false,
      showManagerModal: false,
      showAuthorModal: false,
      educationFlows: [],
      accessType: 'all',
      accessInitType: 'all',
      course: {
        course_title: '',
        course_cover_photo: '',
        course_wide_photo: '',
        course_description: '',
        slug: '',
        availableToBuyFrom: null,
        availableForTrainingFrom: null,
        published: null,
        links: [],
        syncLinks: 1,
        managers: null,
        author: null,
        accessFlows: []
      },
      files: {
        course_cover_photo: null,
        course_wide_photo: null,
      },
    }
  },
  mounted() {
    this.getPreviewData()
  },
  computed: {
    optionsFlows() {
      return [...this.educationFlows].sort((a, b) => a?.id > b?.id ? 1 : -1)
    }
  },
  methods: {
    onChangeInputFile(field, files) {
      if (!(field in this.files)) return

      if (files.length > 0) {
        this.files[field] = files[0]
      }
    },
    getPreviewData() {
      this.loading = true;

      axios.get(`${this.baseUrl}/api/v2/courses/${this.eventId}`, {
        params: {
          with: ['authors']
        }
      })
        .then(res => {
          this.setCourseData(res?.data?.data)
        })
        .catch((e) => {
          this.$toastr.error(this.$t('lk.training.errorLoadData'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    editPreviewData() {
      this.loadBtn = true;

      let formData = new FormData()
      for(let key in this.course) {
        if(key === 'availableToBuyFrom' || key === 'availableForTrainingFrom'){
          if(this.course[key]){
            formData.append(key, moment(this.course[key]).format('YYYY-MM-DD HH:mm:ss'))
          }else{
            formData.append(key, '')
          }
        }else if (key === 'published') {
          formData.append(key, this.course[key] ? 1 : 0)
        }else if (key === 'managers' && this.course[key] !== null) {
          if(this.course[key].length){
            this.course[key].forEach(manager => {
              formData.append('managers[]', manager.id)
            })
          }else{
            formData.append('managers', '')
          }
        }else if(key === 'links'){
          this.course[key].forEach((itemLink, i) => {
            if(itemLink.id){
              formData.append(`links[${i}][id]`, itemLink.id)
            }
            formData.append(`links[${i}][title]`, itemLink.title)
            formData.append(`links[${i}][link]`, itemLink.link)
          })
        }else if(key === 'accessFlows'){
          if(this.course[key].length){
            formData.append('access', 'flows')
            this.course[key].forEach(item => {
              formData.append('accessFlows[]', item.id || '')
            })
          }else{
            formData.append('access', 'all')
            formData.append('accessFlows', '')
          }
        }else if(key === 'author'){
          if(this.course[key]?.id){
            formData.append('authors[]', this.course[key].id)
          }else if(this.course[key]?.user?.id){
            formData.append('author_user_id', this.course[key].user.id)
          }else{
            formData.append('authors', '')
          }
        }else if (!(`${key}` in this.files)) {
          formData.append(key, this.course[key] ? this.course[key] : '')
        }
      }

      for(let key in this.files) {
        if (this.files[key]) {
          formData.append(key, this.files[key])
        }
      }
      axios.post(
        `${this.baseUrl}/api/v2/courses/${this.eventId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          params: {
            with: ['authors']
          }
        })
        .then(res => {
          this.$toastr.success(this.$t('lk.training.successSave'))
          this.setCourseData(res?.data?.data)
        })
        .catch((e) => {
          this.$toastr.error(this.$t('lk.training.errorSave'))
        })
        .finally(() => {
          this.loadBtn = false
        })
    },
    managerTitle(user){
      return user.id + '. ' + user.name + ' (' + user.email + (user.tg_nick ? ', ' + '@' + user.tg_nick : '') + ')'
    },
    setCourseData(data){
      if(!data){
        return
      }
      this.educationFlows = data.educationFlows || []
      for (let key in this.course) {
        if(key === 'author'){
          if(data['authors'] && data['authors'][0]?.id){
            this.course['author'] = new Author(data['authors'][0])
          }else{
            this.course['author'] = null
          }
        }
        if (`${key}` in data) {
          this.course[key] = data[key];
          if(key === 'managers'){
            this.course[key].forEach(item => {
              item.title = this.managerTitle(item)
              item.sync = true
            })
          }else if(key === 'accessFlows'){
            this.course[key] = this.course[key].map(item => {
              return this.educationFlows.find(el => el.id === item) || {
                id: item,
                name: item
              }
            })
          }
        }
      }
      this.accessType = data.accessType || (this.course.accessFlows.length ? 'flows' : 'all')
      this.accessInitType = this.course.accessFlows.length === 0 ? this.accessType : 'all'
    },
    handleUpdateManagerSuccess(user){
      if(this.course.managers.some(item => item.id === user.id)){
        return
      }
      user.title = this.managerTitle(user)
      this.course.managers.push(user)
    },
    getFlowsOptionName(option) {
      return option?.id === 'all' ? 'All' : (option?.id ? option.id + '. ' : '') + (option?.name || option?.id);
    },
    flowsCustomFilter(options, search) {
      return options.filter(option =>{
        return (option.name && option.name.toLowerCase().includes(search.toLowerCase())) ||
          (option.id && option.id.toString().includes(search))
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.trainingPreview{
  &__managers, &__authors{
    font-size: 1.5rem;
    margin-bottom: 3rem;
    margin-top: 3rem;
  }
}
.trainingMaterials {
  width: 100%;
  &__block {
    width: 100%;
    padding: 16px;
    @include media-breakpoint-up(md) {
      padding: 22px 24px;
    }
    @include media-breakpoint-up(lg) {
      padding: 24px 32px;
    }
  }
  &__img {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 20rem;
    }
    img {
      display: block;
      width: 100%;
    }
  }
  &__btn {
    width: fit-content;
  }
  &__header__btns {
    display: flex;
    @include media-breakpoint-up(sm) {
      display: inline-flex;
      margin-top: 27px;
    }
  }
  &__item-author{
    padding: 10px;
  }
}
.v-select-flows {

  /deep/ .vs__dropdown-toggle {
    padding: 10px 6px;
    border: 1px solid #5A6570;
    border-radius: 4px;
  }
  /deep/ .vs__actions{
    display: none;
  }
  /deep/ .vs__selected-options{
    overflow: auto;
    max-height: 120px;
  }

  /deep/ .vs__selected {
    padding: 3px 14px;
    background: var(--primary) !important;
    color: white;
    font-size: 14px;
    line-height: 17px;
    border-radius: 13px;
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    gap: 5px;

    .badge {
    }
  }

  /deep/ .vs__deselect {
    fill: white;
  }

  /deep/ .vs__search {
    color: var(--white);
  }

  /deep/ .vs__dropdown-menu {
    display: flex;
    flex-wrap: wrap;
    background: var(--v-select-bg);
    gap: 8px;
    padding: 20px 13px;
  }

  /deep/ .vs__dropdown-option--selected {
    background: var(--primary) !important;
    border-color: var(--primary) !important;
  }

  /deep/ .vs__dropdown-option {
    padding: 3px 14px;
    background: transparent;
    border: 1px solid var(--white);
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: white;
    border-radius: 13px;

    &--selected {
      .badge {
        color: white !important;
      }
    }

    .badge {
      font-size: 14px !important;
      line-height: 17px !important;
      color: var(--white);
      padding: 0;
    }
  }
  /deep/ .vs__deselect {
    position: relative;

    &:before,
    &:after {
      content: "";
      width: 9px;
      height: 1px;
      background: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }

    &:before {
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
  }
}
</style>
