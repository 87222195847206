var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CModal", {
    staticClass: "authorModal",
    attrs: { centered: "", show: _vm.show },
    on: {
      "update:show": function ($event) {
        return _vm.$emit("update:show", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "header-wrapper",
        fn: function () {
          return [
            _c("div", { staticClass: "authorModal__header" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("lk.training.authorModal.titleAdd"))),
              ]),
              _c(
                "div",
                {
                  staticClass: "authorModal__header-close",
                  on: { click: _vm.close },
                },
                [_vm._v("×")]
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "body-wrapper",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "authorModal__body" },
              [
                _c("v-select-input", {
                  attrs: {
                    options: _vm.list,
                    clearable: false,
                    "get-option-label": (option) => option.searchUserName,
                    placeholder: _vm.$t("lk.training.authorModal.placeholder"),
                  },
                  on: { search: _vm.onSearch },
                  scopedSlots: _vm._u([
                    {
                      key: "prepend",
                      fn: function () {
                        return [_c("CIcon", { attrs: { name: "mail" } })]
                      },
                      proxy: true,
                    },
                    {
                      key: "no-options",
                      fn: function () {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm.$t("general.emptySearch")) + " "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.selected,
                    callback: function ($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "authorModal__btnBlock" },
                  [
                    _c(
                      "CButton",
                      {
                        staticClass: "shadow-none authorModal__btn",
                        attrs: { disabled: !_vm.selected, color: "success" },
                        on: { click: _vm.save },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lk.training.authorModal.btnAdd")) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "CButton",
                      {
                        staticClass: "shadow-none authorModal__btn",
                        attrs: { color: "primary" },
                        on: { click: _vm.close },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("general.cancel")) + " ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.isLoading ? _c("CElementCover") : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "footer-wrapper",
        fn: function () {
          return [_c("span")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }