var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "trainigFlowsTables w-100" },
    [
      _vm.showAddFlowModal
        ? _c("AddFlowModal", {
            ref: "addFlowModal",
            attrs: {
              show: _vm.showAddFlowModal,
              currentFlow: _vm.currentFlow,
              eventId: _vm.eventId,
            },
            on: {
              close: function ($event) {
                _vm.showAddFlowModal = false
              },
              updateFlows: _vm.loadFlows,
              "update:show": function ($event) {
                _vm.showAddFlowModal = $event
              },
            },
          })
        : _vm._e(),
      _vm.loadingFlows
        ? _c("CSpinner", { attrs: { color: "primary", size: "lg" } })
        : _c(
            "div",
            { staticClass: "trainigFlowsTables__wrapper" },
            _vm._l(_vm.flowsList, function (item) {
              return _c("TrainingFlowsTableItem", {
                key: item.id,
                attrs: { flowData: item },
                on: {
                  editFlow: function ($event) {
                    return _vm.toggleShowAddFlowModal(item)
                  },
                  updateFlows: _vm.loadFlows,
                },
              })
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }