var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-training" },
    [
      _c("time-range-selector", {
        staticClass: "tabSelector",
        attrs: { hideTitle: true, options: _vm.btnOptions },
        model: {
          value: _vm.selectedTab,
          callback: function ($$v) {
            _vm.selectedTab = $$v
          },
          expression: "selectedTab",
        },
      }),
      _c(
        "div",
        { staticClass: "header d-flex" },
        [
          _c("h1", { staticClass: "title mb-0 mr-3" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t(`lk.training.headers[${_vm.selectedTab}]`)) +
                " "
            ),
          ]),
          _vm.selectedTab === 3
            ? _c(
                "CLoadingButtonCustom",
                {
                  attrs: { color: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.$refs["materials"].loadMaterials()
                    },
                  },
                },
                [_c("CIcon", { attrs: { name: "cilReload" } })],
                1
              )
            : _vm._e(),
          _vm.selectedTab === 4
            ? _c(
                "CButton",
                {
                  attrs: { color: "primary" },
                  on: { click: _vm.toggleAddFlowModal },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("lk.training.flowTable.addFlow")) + " "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page-training__view-wrapper" },
        [
          _vm.selectedTab === 0
            ? _c("TrainingPreview", { attrs: { eventId: _vm.getRouteId } })
            : _vm._e(),
          _vm.selectedTab === 1
            ? _c("TrainingStudentTable", { attrs: { eventId: _vm.getRouteId } })
            : _vm._e(),
          _vm.selectedTab === 2
            ? _c("TrainingCuratorTable", { attrs: { eventId: _vm.getRouteId } })
            : _vm._e(),
          _vm.selectedTab === 3
            ? _c("TrainingMaterials", {
                ref: "materials",
                attrs: { eventId: _vm.getRouteId },
              })
            : _vm._e(),
          _vm.selectedTab === 4
            ? _c("TrainingFlowsTables", {
                ref: "flows",
                attrs: { eventId: _vm.getRouteId },
              })
            : _vm._e(),
          _vm.selectedTab === 5
            ? _c("TrainingSettings", { attrs: { eventId: _vm.getRouteId } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }