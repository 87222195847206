var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CModal", {
    staticClass: "addCuratorModal",
    attrs: { centered: "", show: _vm.show },
    on: {
      "update:show": function ($event) {
        return _vm.$emit("update:show", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "header-wrapper",
        fn: function () {
          return [
            _c("div", { staticClass: "addCuratorModal__header" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("lk.training.editTariff.modalTitle"))),
              ]),
              _c(
                "div",
                {
                  staticClass: "addCuratorModal__header-close",
                  on: { click: _vm.close },
                },
                [_vm._v("×")]
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "body-wrapper",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "addCuratorModal__body" },
              [
                _c("CSelect", {
                  attrs: {
                    value: _vm.selectedTariff,
                    label: `${_vm.$t("lk.permission.subscription")}:`,
                    placeholder: _vm.$t("lk.permission.chooseAPlan"),
                    options: _vm.tariffList,
                  },
                  on: {
                    "update:value": function ($event) {
                      _vm.selectedTariff = $event
                    },
                  },
                }),
                _c(
                  "div",
                  { staticClass: "addCuratorModal__btnBlock" },
                  [
                    _c(
                      "CButton",
                      {
                        staticClass: "shadow-none addCuratorModal__btn",
                        attrs: {
                          disabled: !_vm.isTariffChanged || _vm.isLoading,
                          color: "success",
                        },
                        on: { click: _vm.saveTariff },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("general.save")) + " ")]
                    ),
                    _c(
                      "CButton",
                      {
                        staticClass: "shadow-none addCuratorModal__btn",
                        attrs: { color: "primary" },
                        on: { click: _vm.close },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("general.cancel")) + " ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.isLoading ? _c("CElementCover") : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "footer-wrapper",
        fn: function () {
          return [_c("span")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }