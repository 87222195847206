<template>
  <div class="trainingMaterials">
    <CCard
      color="second-card-bg"
      class="trainingMaterials__block"
    >
      <div class="trainingMaterials__header d-flex">
        <div class="trainingMaterials__header__h row align-items-end">
          <CInput
            :label="$t('lk.training.topic')"
            :value="lesson.theme ? lesson.theme : ''"
            @input="lesson.theme =$event"
            type="text"
            class="pl-0 pr-3 col-lg-4"
          />
          <div class="form-group pl-0 pr-3 col-lg-8">
            <label>{{ $t('lk.training.accessFlows') }}</label>
            <v-select
              ref="vselect"
              :closeOnSelect="false"
              v-model="lesson.accessFlows"
              :loading="isLessonLoading"
              :options="optionsFlows"
              :filter="flowsCustomFilter"
              :getOptionKey="(option) => option?.id"
              :getOptionLabel="(option) => getFlowsOptionName(option)"
              class="v-select-flows"
              multiple
              :placeholder="lesson.accessInitType === 'all' ? $t('lk.training.accessFlowsAll') : $t('lk.training.accessFlowsFlows')"
            >
              <template v-slot:option="option">
                <div class="rounded-pill">
                <span class="badge">
                {{ getFlowsOptionName(option) }}
              </span>
                </div>
              </template>
              <template #selected-option-container="{ option, deselect, multiple, disabled }">
              <span class="vs__selected badge">
                {{ getFlowsOptionName(option) }}
                <button
                  v-if="multiple"
                  :disabled="disabled"
                  @click="deselect(option)"
                  type="button"
                  class="vs__deselect"
                  :title="`${$t('general.deselect')} ${getFlowsOptionName(option)}`"
                  :aria-label="`${$t('general.deselect')} ${getFlowsOptionName(option)}`"
                >
                </button>
              </span>
              </template>
            </v-select>
          </div>
        </div>
        <CInput
          :label="$t('lk.training.order')"
          :value="lesson.order ? lesson.order : ''"
          @input="lesson.order =$event"
          type="number"
        />
        <CButtonGroup class="trainingMaterials__header__btns">
          <CButton
            :color="lesson.live ? 'success' : 'dark'"
            @click="lesson.live = true"
            class="trainingMaterials__header__btn"
          >
            Live
          </CButton>
          <CButton
            :color="!lesson.live ? 'danger' : 'dark'"
            @click="lesson.live = false"
            class="trainingMaterials__header__btn"
          >
            Offline
          </CButton>
        </CButtonGroup>
        <CButtonGroup class="trainingMaterials__header__btns">
          <CButton
            :color="lesson.publishedAt ? 'success' : 'dark'"
            @click="setLessonPublished"
            class="trainingMaterials__header__btn"
          >
            {{ $t("lk.training.active") }}
          </CButton>
          <CButton
            :color="!lesson.publishedAt ? 'danger' : 'dark'"
            @click="removeLessonPublished"
            class="trainingMaterials__header__btn"
          >
            {{ $t("lk.training.notActive") }}
          </CButton>
        </CButtonGroup>
        <CLoadingButton
          :loading="isLessonDeleting"
          color="danger"
          @click.native="deleteItem"
        >
          <CIcon name="cilDelete"></CIcon>
        </CLoadingButton>
      </div>
      <div class="trainingMaterials__inputBlock">
        <CInput
          :label="$t('lk.training.name')"
          :value="lesson.name ? lesson.name : ''"
          @input="lesson.name = $event"
          type="text"
          class="w-100"
        />
        <CInput
          :label="$t('lk.training.link')"
          type="text"
          class="w-100"
          :value="lesson.streamUrl ? lesson.streamUrl : ''"
          @input="lesson.streamUrl = $event"
        />
      </div>
      <tinymce
        v-model="lesson.shortDescription"
        :label="$t('lk.training.description')"
        class="form-group trainingMaterials__textarea"
      />
      <div>
        <CInputCheckbox
          :checked.sync="lesson.has_homework"
          :label="$t('lk.training.homeWork')"
          custom
          class="mb-3 mt-3"
        />
      </div>
      <div>
        <tinymce
          v-model="lesson.description"
          class="form-group trainingMaterials__textarea"
        />
      </div>

      <div class="trainingMaterials__primary-video-block d-md-flex mb-4">
        <div class="d-flex flex-column trainingMaterials__file-left">
          <span>
            {{ $t('lk.training.loadTitle') }}
          </span>
          <div class="d-flex align-items-center mt-2">
            <CLoadingButton
              class="mr-2"
              :loading="isLessonLoading"
              color="success"
              @click.native="openVideoList('primary')"
            >
              {{
                $t(primaryVideo && primaryVideo.filename ? 'lk.videoEncrypt.replaceVideo' : 'lk.videoEncrypt.chooseVideo')
              }}
            </CLoadingButton>
            <div v-if="primaryVideo && primaryVideo.filename">
              <span>
                {{ $t('lk.videoEncrypt.selectedVideo') }}:
              </span>
              <span class="trainingMaterials__chat">
                {{ primaryVideo.filename }}
              </span>
              <span @click="removeVideo('primary')">
                <CIcon width="0.7rem" name="cil-x" class="trainingMaterials__close-icon"/>
              </span>
            </div>
          </div>

          <div v-if="primaryVideo" class="additionalMaterialCard__video-time-code-wrapper mt-3">
            <div class="additionalMaterialCard__video-time-code-block d-flex align-items-center">
              <CTextarea
                :label="$t('lk.videoEncrypt.timeCodes')"
                rows="5"
                class="mb-0 w-100"
                v-model="primaryVideoMarkers"
              />
            </div>
          </div>
        </div>
        <div class="trainingMaterials__file-right">
            <span>
              {{ $t(lesson.chat ? 'lk.training.editChatFile' : 'lk.training.addChatFile') }}
            </span>
            <div class="d-flex mt-2 align-items-center">
              <CLoadingButton
                class="mr-2"
                :loading="isLessonLoading"
                color="success"
                @click.native="selectChatFile()"
              >
                {{ $t(lesson.chat ? 'lk.training.editChatFileBtn' : 'lk.training.addChatFileBtn') }}
              </CLoadingButton>
              <span class="trainingMaterials__chat">{{lesson.chatFileName}}</span>
            </div>

          </div>
      </div>
      <div class="trainingMaterials__additional-materials">
        <div class="trainingMaterials__materials-wrapper">
          <div>
            <h3 v-if="lesson.videos.length">
              {{ $t('lk.videoEncrypt.additionalMaterials') }}
            </h3>
            <AdditionalMaterialCard
              v-for="(item,index) in lesson.videos"
              :key="index"
              :primaryVideo="primaryVideo"
              @removeVideo="removeVideo"
              @removeVideoField="removeVideoField"
              @openVideoList="openVideoList"
              :video="item"
              :index="index"
            >
            </AdditionalMaterialCard>
          </div>
        </div>
        <div class="d-flex flex-column justify-content-end trainingMaterials__bottom mt-3">
          <div class="trainingMaterials__bottom-wrapper">
            <div @click="addVideoField"
                class="d-flex align-items-center mb-2 trainingMaterials__bottom-addButton">
              <div class="trainingMaterials__add-video-button mr-2">
                +
              </div>
              <span>
                {{ $t('lk.videoEncrypt.moreVideo') }}
              </span>
            </div>
            <CLoadingButtonCustom
              :loading="isLessonLoading"
              color="primary"
              @click.native="saveLesson"
              class="trainingMaterials__bottom-btn"
            >
              {{ $t("lk.training.btnSave") }}
            </CLoadingButtonCustom>
          </div>
        </div>
      </div>
    </CCard>
    <VideoListModal
      v-if="showVideoList"
      @selcetVideo="addVideo"
      :show.sync="showVideoList"
      :videos="lesson?.videos || []"
    />
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import VideoListModal from "@/components/training/VideoListModal"
import AdditionalMaterialCard from "@/components/training/AdditionalMaterialCard"
import { hhmmssToSec, secToHHMMSS } from '@/assets/js/timeCode'
import TimeCodeItem from '@/components/training/TimeCodeItem'
import VSelectInput from "@/components/VSelectInput";
import VSelect from 'vue-select';
import Lesson from '@/assets/js/Lesson.class';
import Tinymce from "@/components/common/Tinymce";

export default {
  name: 'EditTrainingMaterialCard',
  components: {
    VideoListModal,
    AdditionalMaterialCard,
    TimeCodeItem,
    VSelectInput,
    VSelect,
    Tinymce
  },
  props: {
    lesson: {
      type: Lesson
    },
    eventId: {
      type: Number
    },
    newOrder: [Number],
    loadingMaterials: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      isLessonLoading: false,
      isLessonDeleting: false,
      showVideoList: false,
      order: "",
      chatFile: null,
      accessFlows: null,
    }
  },
  computed: {
    primaryVideoMarkers:{
      get() {
        let formatedData = ''
        if(this.primaryVideo.markers && this.primaryVideo.markers.length){
          this.primaryVideo.markers.forEach((item)=>{
          formatedData += `${secToHHMMSS(item.time)} - ${item.text}\n`
          })
        }
        this.primaryVideo.stringFormatMarkers = formatedData
        return formatedData
      },
      set(value) {
        this.primaryVideo.stringFormatMarkers = value
      }
    },
    isParentEvent() {
      return this.eventId === this.lesson.id
    },
    primaryVideo() {
      if (this.lesson.videos.length) {
        return this.lesson.videos.find((item) => item.primary)
      }
      return null
    },
    optionsFlows() {
      return [...this.lesson.flows].sort((a, b) => a?.id > b?.id ? 1 : -1)
    }
  },
  methods: {
    selectChatFile() {
      let input = document.createElement('input');
      input.type = 'file';
      input.click();

      input.onchange = e => {
        this.lesson.chat = e.target.files[0];
        this.lesson.chatFileName = e.target.files[0].name
      }
    },
    addVideoField() {
      this.lesson.videos.push({})
    },
    removeVideoField(index) {
      this.lesson.videos.splice(index, 1)
    },
    removeVideo(order) {
      if (order === 'primary') {
        this.lesson.primaryVideoId = ''
        this.lesson.videos.splice( this.lesson.videos.findIndex((item)=> item.primary), 1, {primary: true})
      } else {
        this.lesson.videos.splice(order, 1, {})
      }
    },
    addVideo(value) {
      if (this.order === 'primary') {
        this.lesson.primaryVideoId = value.id
        value = {...value, primary: true}
        if (this.primaryVideo) {
          this.lesson.videos.splice(0, 1, value)
        } else {
          this.lesson.videos.unshift(value)
        }
      } else {
        this.lesson.videos.splice(this.primaryVideo ? this.order - 1 : this.order - 2, 1, value)
      }
      this.showVideoList = false
    },
    openVideoList(order) {
      this.showVideoList = true
      this.order = order
    },
    async deleteItem() {
      this.isLessonDeleting = true
      const res = await axios.delete(`/api/v2/lessons/${this.lesson.id}`)
      if (res?.data) {
        this.$emit('updateLessons')
        this.$toastr.success('Урок удален')
      }
      this.isLessonDeleting = false
    },
    async saveLesson() {
      const newOrderData = this.lesson.videos.filter(item => item.filename).map((item, index) => {
        const formatedMarkers = []
          item.stringFormatMarkers.split("\n").forEach((marker)=>{
            marker.split('-')
            formatedMarkers.push({
              time: hhmmssToSec(marker.split('-')[0]),
              text: marker.split('-')[1]?.trim() || ''
            })
          })
        return {...item, order: this.primaryVideo ? index + 1 : index + 2, markers: formatedMarkers.filter(item => item.text)}
      })
      this.lesson.videos = newOrderData
      try {
        this.isLessonLoading = true
        let res
        if (this.isParentEvent) {
          res = await axios.post(`/api/v2/events/${this.lesson.id}`, this.lesson.formData)
        } else {
          res = await axios.post(`/api/v2/lessons/${this.lesson.id}`, this.lesson.formData)
        }
        if (res?.data) {
          this.$toastr.success('Урок обновлен')
          this.$emit('updateLessons', this.lesson.id)
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.isLessonLoading = false
      }
    },
    setLessonPublished() {
      this.lesson.publishedAt = moment().format('YYYY-MM-DD')
    },
    removeLessonPublished() {
      this.lesson.publishedAt = false
    },
    getFlowsOptionName(option)
    {
      return option?.id === 'all' ? 'All' : (option?.id ? option.id + '. ' : '') + (option?.name || option?.id);
    },
    flowsCustomFilter(options, search) {
      return options.filter(option =>{
        return (option.name && option.name.toLowerCase().includes(search.toLowerCase())) ||
          (option.id && option.id.toString().includes(search))
      });
    },
  },
  mounted() {
    if (this.lesson.newOrder) this.lesson.order = this.lesson.newOrder
    if (!this.lesson.videos.length) {
      this.addVideoField()
    } else if (this.lesson.videos.length === 1 && this.lesson.primaryVideoId)
      this.addVideoField()
  },
  watch: {
    loadingMaterials(val){
      this.isLessonLoading = val
    }
  }
}
</script>

<style lang="scss" scoped>
.trainingMaterials {
  width: 100%;

  &__chat {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__header {
    justify-content: space-between;
    margin-bottom: 12px;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    align-items: center;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      width: auto;
    }


    &__btns {
      width: 100%;
      margin-top: 12px;

      @include media-breakpoint-up(md) {
        width: auto;
        margin-top: 0;
      }
    }

    &__h {
      width: 100%;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin: 0 auto 0 0;
      @include media-breakpoint-up(md) {
        width: 80%;
      }
    }
  }

  &__chat-log {
    margin-left: auto;
  }

  &__inputBlock {
    gap: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: baseline;

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__block {
    width: 100%;
    padding: 16px;

    @include media-breakpoint-up(md) {
      padding: 22px 24px;
    }

    @include media-breakpoint-up(lg) {
      padding: 24px 32px;
    }
  }

  &__bottom {
    width: 100%;
    @include media-breakpoint-up(md) {
      align-items: flex-end;
    }
    &-wrapper {
      width: 100%;
      @include media-breakpoint-up(md) {
        width: 50%;
      }
      @include media-breakpoint-up(xl) {
        width: 25%;
      }
    }
    &-addButton {
      width: fit-content;
      cursor: pointer;

      &:hover {
        & span {
          color: var(--sub-btn)
        }

        & div {
          background: var(--sub-btn);
        }
      }
    }

    &-btn {
      width: 100%;
      height: 45px;
    }
  }

  &__inputFileTitle {
    display: block;
    margin-bottom: 0.5rem;
  }

  &__close-icon {
    cursor: pointer;
    color: var(--danger);

    &:hover {
      color: var(--sub-btn);
    }
  }

  &__add-video-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 0;
  }

  &__file-left,
  &__file-right {
    width: 100%;
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
      width: 50%;
    }
  }
}

.additionalMaterialCard__video-time-code-add-button {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: var(--primary);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;

  &:hover {
    background: var(--sub-btn);
  }
}

.v-select-flows {

  /deep/ .vs__dropdown-toggle {
    padding: 10px 6px;
    border: 1px solid #5A6570;
    border-radius: 4px;
  }
  /deep/ .vs__actions{
    display: none;
  }
  /deep/ .vs__selected-options{
    overflow: auto;
    max-height: 120px;
  }

  /deep/ .vs__selected {
    padding: 3px 14px;
    background: var(--primary) !important;
    color: white;
    font-size: 14px;
    line-height: 17px;
    border-radius: 13px;
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    gap: 5px;

    .badge {
    }
  }

  /deep/ .vs__deselect {
    fill: white;
  }

  /deep/ .vs__search {
    color: var(--white);
  }

  /deep/ .vs__dropdown-menu {
    display: flex;
    flex-wrap: wrap;
    background: var(--v-select-bg);
    gap: 8px;
    padding: 20px 13px;
  }

  /deep/ .vs__dropdown-option--selected {
    background: var(--primary) !important;
    border-color: var(--primary) !important;
  }

  /deep/ .vs__dropdown-option {
    padding: 3px 14px;
    background: transparent;
    border: 1px solid var(--white);
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: white;
    border-radius: 13px;

    &--selected {
      .badge {
        color: white !important;
      }
    }

    .badge {
      font-size: 14px !important;
      line-height: 17px !important;
      color: var(--white);
      padding: 0;
    }
  }
  /deep/ .vs__deselect {
    position: relative;

    &:before,
    &:after {
      content: "";
      width: 9px;
      height: 1px;
      background: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }

    &:before {
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
  }
}
</style>
