<template>
  <CModal
  centered
  :show="show"
  @update:show="$emit('update:show', $event)"
  class="addCuratorModal">
    <template #header-wrapper>
      <div class="addCuratorModal__header">
        <span>{{ $t('lk.training.editTariffFinishDate.modalTitle') }}</span>
        <div @click="close" class="addCuratorModal__header-close">×</div>
      </div>
    </template>

    <template #body-wrapper>
      <div class="addCuratorModal__body">
        <v-date-picker
        mode="date"
        v-model="selectedDate"
        :is-dark="$store.getters.darkMode"
        :masks="{
          input: 'DD.MM.YYYY',
        }"
        timezone="utc"
        :locale="$root.$i18n.locale"
        :available-dates='[{ start: new Date(), end: null }, {start: new Date(currentStudent.finished_at), end: new Date(currentStudent.finished_at)}]'
      >
        <template v-slot="{ inputValue, inputEvents }">
          <CInput
            :value="inputValue"
            :placeholder="$t('lk.permission.selectAnExpirationDate')"
            class="input"
            readonly
            v-on="inputEvents"
          >
            <template #prepend-content>
              <CIcon
                v-if="inputValue"
                name="cil-x"
                class="text-danger reset-date"
                @click.native="selectedDate = new Date(currentStudent.finished_at)"
              />
              <CIcon
                v-else
                name="cil-calendar"
              />
            </template>
          </CInput>
        </template>
      </v-date-picker>

        <div class="addCuratorModal__btnBlock">
          <CButton
            @click="saveDate"
            :disabled="!isDateChanged || isLoading"
            class="shadow-none addCuratorModal__btn"
            color="success"
          >
            {{ $t('general.save') }}
          </CButton>
          <CButton class="shadow-none addCuratorModal__btn" color="primary" @click="close">
            {{ $t('general.cancel') }}
          </CButton>
        </div>
      </div>
      <CElementCover v-if="isLoading" />
    </template>

    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>
import axios from 'axios'
import { DatePicker } from 'v-calendar'
import moment from 'moment'

export default {
  name: 'EditFinishDateModal',
  components: {
    'v-date-picker': DatePicker,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    eventId: {
      type: Number,
      required: true,
    },
    currentStudent: {
      type: [Object, null],
      required: null,
    },
  },

  data() {
    return {
      isLoading: false,
      selectedDate: moment(this.currentStudent.finished_at).utc(true).toDate(),
    }
  },

  computed:{
    isDateChanged(){
      return this.selectedDate.getTime() !== new Date(this.currentStudent.finished_at).getTime()
    }
  },

  methods: {
    saveDate() {
      if (!this.isLoading && this.isDateChanged) {
        this.isLoading = true
        axios
          .post(`/api/v2/events/${this.eventId}/users`, {
            id: [this.currentStudent.id],
            finished_at: this.selectedDate
          })
          .then(() => {
            this.$toastr.success(this.$t('lk.training.editTariffFinishDate.changesSaved'))
            this.$emit('success')
            this.close()
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.ccard {
  margin-bottom: 0;
  max-height: calc(100vh - 300px);
  max-height: calc(100dvh - 300px);

  @include media-breakpoint-down(xs) {
    .ccard-body {
      padding: 1rem 1.75rem;
    }
  }

  @include media-breakpoint-up(sm) {
    .ccard-body {
      padding: 1.25rem 1.75rem;
    }
  }

  @include media-breakpoint-up(xl) {
    margin: 0;
  }
}

.addCuratorModal {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 0;
    font-size: 21px;
    line-height: 1;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      padding: 24px 32px 0;
    }

    &-close {
      font-size: 23px;
      font-weight: 100;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $danger;
      }
    }
  }

  &__btn {
    width: 100%;
    height: 44px;
  }

  &__btnBlock {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: 20px;
    }
  }

  &__body {
    padding: 20px 16px 20px;

    @include media-breakpoint-up(md) {
      padding: 24px 32px 32px;
    }
  }
}
</style>
