var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CModal", {
    staticClass: "addCuratorModal",
    attrs: { centered: "", show: _vm.show },
    on: {
      "update:show": function ($event) {
        return _vm.$emit("update:show", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "header-wrapper",
        fn: function () {
          return [
            _c("div", { staticClass: "addCuratorModal__header" }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("lk.training.editTariffFinishDate.modalTitle"))
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "addCuratorModal__header-close",
                  on: { click: _vm.close },
                },
                [_vm._v("×")]
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "body-wrapper",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "addCuratorModal__body" },
              [
                _c("v-date-picker", {
                  attrs: {
                    mode: "date",
                    "is-dark": _vm.$store.getters.darkMode,
                    masks: {
                      input: "DD.MM.YYYY",
                    },
                    timezone: "utc",
                    locale: _vm.$root.$i18n.locale,
                    "available-dates": [
                      { start: new Date(), end: null },
                      {
                        start: new Date(_vm.currentStudent.finished_at),
                        end: new Date(_vm.currentStudent.finished_at),
                      },
                    ],
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ inputValue, inputEvents }) {
                        return [
                          _c(
                            "CInput",
                            _vm._g(
                              {
                                staticClass: "input",
                                attrs: {
                                  value: inputValue,
                                  placeholder: _vm.$t(
                                    "lk.permission.selectAnExpirationDate"
                                  ),
                                  readonly: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend-content",
                                      fn: function () {
                                        return [
                                          inputValue
                                            ? _c("CIcon", {
                                                staticClass:
                                                  "text-danger reset-date",
                                                attrs: { name: "cil-x" },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    _vm.selectedDate = new Date(
                                                      _vm.currentStudent.finished_at
                                                    )
                                                  },
                                                },
                                              })
                                            : _c("CIcon", {
                                                attrs: { name: "cil-calendar" },
                                              }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              inputEvents
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.selectedDate,
                    callback: function ($$v) {
                      _vm.selectedDate = $$v
                    },
                    expression: "selectedDate",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "addCuratorModal__btnBlock" },
                  [
                    _c(
                      "CButton",
                      {
                        staticClass: "shadow-none addCuratorModal__btn",
                        attrs: {
                          disabled: !_vm.isDateChanged || _vm.isLoading,
                          color: "success",
                        },
                        on: { click: _vm.saveDate },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("general.save")) + " ")]
                    ),
                    _c(
                      "CButton",
                      {
                        staticClass: "shadow-none addCuratorModal__btn",
                        attrs: { color: "primary" },
                        on: { click: _vm.close },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("general.cancel")) + " ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.isLoading ? _c("CElementCover") : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "footer-wrapper",
        fn: function () {
          return [_c("span")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }