<template>
  <div class="w-100">
    <AddToNewModal :show="showModal" :id="lastUpdatedLesson" @close="showModal = false" />
    <CSpinner v-if="loadingMaterials" color="primary" size="lg" />
    <template>
      <EditTrainingMaterialCard
        v-for="(lesson, index) in activeMaterials"
        :key="index"
        :event-id="eventId"
        :lesson="lesson"
        :loadingMaterials="loadingMaterials"
        @updateLessons="updateLessons"
      />
      <CLoadingButton
        color="primary"
        :loading="loadingCreateLesson"
        @click.native="addLesson"
      >
        Добавить урок
      </CLoadingButton>
    </template>
  </div>
</template>
<script>
import axios from "axios";
import Lesson from "@/assets/js/Lesson.class";
import EditTrainingMaterialCard from "@/components/training/EditTrainingMaterialCard";
import AddToNewModal from "@/components/training/AddToNewModal"

export default {
  name: 'TrainingMaterials',
  components: {EditTrainingMaterialCard, AddToNewModal},
  props: {
    eventId: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      activeMaterials: [],
      loadingCreateLesson: false,
      loadingMaterials: false,
      showModal: false,
      lastUpdatedLesson: null,
    }
  },
  mounted() {
    this.loadMaterials()
  },
  methods: {
    updateLessons(e) {
      this.loadMaterials().then(() => {
        this.lastUpdatedLesson = e
        this.showModal = true
      })
    },
    async addLesson() {
      this.loadingCreateLesson = true
      const { data } = await axios.post(`/api/v2/lessons`, {parent_id: this.eventId})
      this.activeMaterials.push(new Lesson({...data.data, newOrder: this.newOrder}))
      this.loadingCreateLesson = false
    },
    loadMaterials() {
      return new Promise(async (resolve, reject) => {
        try {
          this.loadingMaterials = true
          const { data } = await axios.get(`/api/v2/events/${this.eventId}`)
          this.activeMaterials = []
          if (data?.data) {
            const lesson = new Lesson(data.data),
                  flows = lesson.flows
            //@todo перенос в превью
            //this.activeMaterials.push(lesson)
            data.data.children?.forEach(lesson => {
              if(!lesson.educationFlows){
                lesson.educationFlows = flows
              }
              this.activeMaterials.push(new Lesson(lesson))
            })
            resolve(true);
          }
        } catch (e) {
          console.log(e)
          reject(e)
        } finally {
          this.loadingMaterials = false
        }
      })
    },
  },
  computed: {
    newOrder() {
      let result = 0;
      this.activeMaterials.forEach(el =>{
        if (el.order > result) result = el.order
      })
      if (result) return result + 1
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
