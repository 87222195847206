<template>
  <CModal
  centered
  :show="show"
  @update:show="$emit('update:show', $event)"
  class="add-flow-modal"
  :title="title">
    <CForm @submit.prevent="saveFlow">
      <CInput
        :value="flowName"
        :placeholder="`${$t('lk.training.flowTable.flowNameInputPlaceholder')}`"
        :label="`${$t('lk.videoEncrypt.EditModal.name')}:`"
        :invalid-feedback="errors.flowName"
        :is-valid="detectErrorField(errors.flowName)"
        @input="changeFlowName"
      />
      <div class="d-flex">
        <CInput
          :label="$t('lk.training.flowTable.dateInputLabel')"
          :placeholder="$t('lk.training.start_at')"
          type="datetime-local"
          class="mr-3"
          :value="startAt"
          @input="startAt = $event"
        />

        <CInput
          class="align-self-end"
          :placeholder="$t('lk.training.finish_at')"
          type="datetime-local"
          :value="finishAt"
          @input="finishAt = $event"
        />
      </div>
        <label>
          {{ $t('lk.training.flowTable.programInputLabel') }}:
        </label>
        <v-select-input
          class="mb-0"
          :placeholder="$t('lk.training.flowTable.programInputPlacholder')"
          :disabled="!!event"
          v-model="event"
          :options="eventList"
        >
          <template #no-options>
            {{ $t('general.emptySearch') }}
          </template>
        </v-select-input>
      <div class="add-flow-modal__button-wrapper d-flex w-100 mt-4 flex-wrap">
        <CLoadingButtonCustom
          class="mr-3 flex-grow-1"
          color="success"
          :loading="loading"
          :disabled="loading"
          @click.native="saveFlow"
        >
          {{ $t('general.save') }}
        </CLoadingButtonCustom>
        <CButton
          class="flex-grow-1"
          color="dark"
          @click="close"
        >
          {{ $t('general.cancel') }}
        </CButton>
        <CLoadingButtonCustom
          v-if="currentFlow"
          class="w-100 mt-3"
          color="danger"
          :loading="loading"
          :disabled="loading"
          @click.native="deleteflow"
        >
          {{ $t('general.delete') }}
        </CLoadingButtonCustom>

      </div>
    </CForm>
    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>
import { detectErrorField } from '@/assets/js/axios/responseErrorHandler';
import VSelectInput from "@/components/VSelectInput";
import axios from 'axios'

export default {
  name: 'AddFlowModal',
  components: {
    VSelectInput,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    currentFlow: {
      type: [Object, null],
      default: null
    },
    eventId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      detectErrorField,
      flowName: this.currentFlow?.name || '',
      loading: false,
      startAt: this.currentFlow?.start_at || '',
      finishAt: this.currentFlow?.finish_at || '',
      event: this.currentFlow ? { label: this.currentFlow?.event?.name, value: this.currentFlow?.event?.id } : null,
      eventList: [],
      errors: {
        flowName: null
      }
    }
  },
  async beforeMount(){
    await this.getEvents()
  },
  methods: {
    async deleteflow(){
      try {
        this.loading = true
        if(this.currentFlow){
          await axios.delete(`/api/v2/education-flows/${this.currentFlow.id}`)
        }
        this.$toastr.success(this.$t("general.dataSaved"));
        this.$emit('updateFlows')
        this.close()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async saveFlow(){
      if (Object.values(this.errors).some(val => {
        if (val) this.$toastr.error(this.$t('general.notValidate'))
        return val
      })) return
      try {
        this.loading = true
        if(this.currentFlow){
          await axios.put(`/api/v2/education-flows/${this.currentFlow.id}`, {
            event_id: this.event.value,
            name: this.flowName,
            start_at: this.startAt,
            finish_at: this.finishAt
          })
        }else{
          await axios.post(`/api/v2/education-flows`, {
            event_id: this.event.value,
            name: this.flowName,
            start_at: this.startAt,
            finish_at: this.finishAt
          })
        }
        this.$toastr.success(this.$t("general.dataSaved"));
        this.$emit('updateFlows')
        this.close()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getEvents(){
      try {
        this.eventList = []
        const { data } = await axios.get(`/api/v2/courses`)
        if (data?.data) {
          const idIndex = []
          data.data.forEach(item => {
            if(idIndex.includes(item.id)){
              return
            }
            idIndex.push(item.id)
            if(Number(item.id) === Number(this.eventId)){
              this.event = {
                value: item.id,
                label: item.name
              }
            }
            this.eventList.push({
              value: item.id,
              label: item.name
            })
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    validation (val) {
      if (!val) {
        return this.$t('general.requiredField')
      }
      return null
    },
    changeFlowName(val){
      this.flowName = val
      this.errors.flowName = this.validation(this.flowName)
    },
    close() {
      this.$emit('close')
    },
  },
  computed:{
    title(){
      return this.currentFlow ?
      this.$t('lk.training.flowTable.editFlow'):
      this.$t('lk.training.flowTable.addFlow')
    }
  }
}
</script>

<style lang="scss" scoped>
.add-flow-modal {
  &__button-wrapper{
    button{
      height: 50px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 0;
    font-size: 21px;
    line-height: 1;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      padding: 24px 32px 0;
    }

    &-close {
      font-size: 23px;
      font-weight: 100;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $danger;
      }
    }
  }

  &__btn {
    width: 100%;
    height: 44px;
  }

  &__btnBlock {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: 20px;
    }
  }

  &__body {
    padding: 20px 16px 20px;

    @include media-breakpoint-up(md) {
      padding: 24px 32px 32px;
    }
  }
}
</style>
