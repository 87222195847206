var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "trainingMaterials" },
    [
      _c(
        "CCard",
        {
          staticClass: "trainingMaterials__block",
          attrs: { color: "second-card-bg" },
        },
        [
          _c(
            "div",
            { staticClass: "trainingMaterials__header d-flex" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "trainingMaterials__header__h row align-items-end",
                },
                [
                  _c("CInput", {
                    staticClass: "pl-0 pr-3 col-lg-4",
                    attrs: {
                      label: _vm.$t("lk.training.topic"),
                      value: _vm.lesson.theme ? _vm.lesson.theme : "",
                      type: "text",
                    },
                    on: {
                      input: function ($event) {
                        _vm.lesson.theme = $event
                      },
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "form-group pl-0 pr-3 col-lg-8" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("lk.training.accessFlows"))),
                      ]),
                      _c("v-select", {
                        ref: "vselect",
                        staticClass: "v-select-flows",
                        attrs: {
                          closeOnSelect: false,
                          loading: _vm.isLessonLoading,
                          options: _vm.optionsFlows,
                          filter: _vm.flowsCustomFilter,
                          getOptionKey: (option) => option?.id,
                          getOptionLabel: (option) =>
                            _vm.getFlowsOptionName(option),
                          multiple: "",
                          placeholder:
                            _vm.lesson.accessInitType === "all"
                              ? _vm.$t("lk.training.accessFlowsAll")
                              : _vm.$t("lk.training.accessFlowsFlows"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "option",
                            fn: function (option) {
                              return [
                                _c("div", { staticClass: "rounded-pill" }, [
                                  _c("span", { staticClass: "badge" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.getFlowsOptionName(option)) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "selected-option-container",
                            fn: function ({
                              option,
                              deselect,
                              multiple,
                              disabled,
                            }) {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "vs__selected badge" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.getFlowsOptionName(option)) +
                                        " "
                                    ),
                                    multiple
                                      ? _c("button", {
                                          staticClass: "vs__deselect",
                                          attrs: {
                                            disabled: disabled,
                                            type: "button",
                                            title: `${_vm.$t(
                                              "general.deselect"
                                            )} ${_vm.getFlowsOptionName(
                                              option
                                            )}`,
                                            "aria-label": `${_vm.$t(
                                              "general.deselect"
                                            )} ${_vm.getFlowsOptionName(
                                              option
                                            )}`,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return deselect(option)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.lesson.accessFlows,
                          callback: function ($$v) {
                            _vm.$set(_vm.lesson, "accessFlows", $$v)
                          },
                          expression: "lesson.accessFlows",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("CInput", {
                attrs: {
                  label: _vm.$t("lk.training.order"),
                  value: _vm.lesson.order ? _vm.lesson.order : "",
                  type: "number",
                },
                on: {
                  input: function ($event) {
                    _vm.lesson.order = $event
                  },
                },
              }),
              _c(
                "CButtonGroup",
                { staticClass: "trainingMaterials__header__btns" },
                [
                  _c(
                    "CButton",
                    {
                      staticClass: "trainingMaterials__header__btn",
                      attrs: { color: _vm.lesson.live ? "success" : "dark" },
                      on: {
                        click: function ($event) {
                          _vm.lesson.live = true
                        },
                      },
                    },
                    [_vm._v(" Live ")]
                  ),
                  _c(
                    "CButton",
                    {
                      staticClass: "trainingMaterials__header__btn",
                      attrs: { color: !_vm.lesson.live ? "danger" : "dark" },
                      on: {
                        click: function ($event) {
                          _vm.lesson.live = false
                        },
                      },
                    },
                    [_vm._v(" Offline ")]
                  ),
                ],
                1
              ),
              _c(
                "CButtonGroup",
                { staticClass: "trainingMaterials__header__btns" },
                [
                  _c(
                    "CButton",
                    {
                      staticClass: "trainingMaterials__header__btn",
                      attrs: {
                        color: _vm.lesson.publishedAt ? "success" : "dark",
                      },
                      on: { click: _vm.setLessonPublished },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lk.training.active")) + " ")]
                  ),
                  _c(
                    "CButton",
                    {
                      staticClass: "trainingMaterials__header__btn",
                      attrs: {
                        color: !_vm.lesson.publishedAt ? "danger" : "dark",
                      },
                      on: { click: _vm.removeLessonPublished },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("lk.training.notActive")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "CLoadingButton",
                {
                  attrs: { loading: _vm.isLessonDeleting, color: "danger" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.deleteItem.apply(null, arguments)
                    },
                  },
                },
                [_c("CIcon", { attrs: { name: "cilDelete" } })],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "trainingMaterials__inputBlock" },
            [
              _c("CInput", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("lk.training.name"),
                  value: _vm.lesson.name ? _vm.lesson.name : "",
                  type: "text",
                },
                on: {
                  input: function ($event) {
                    _vm.lesson.name = $event
                  },
                },
              }),
              _c("CInput", {
                staticClass: "w-100",
                attrs: {
                  label: _vm.$t("lk.training.link"),
                  type: "text",
                  value: _vm.lesson.streamUrl ? _vm.lesson.streamUrl : "",
                },
                on: {
                  input: function ($event) {
                    _vm.lesson.streamUrl = $event
                  },
                },
              }),
            ],
            1
          ),
          _c("tinymce", {
            staticClass: "form-group trainingMaterials__textarea",
            attrs: { label: _vm.$t("lk.training.description") },
            model: {
              value: _vm.lesson.shortDescription,
              callback: function ($$v) {
                _vm.$set(_vm.lesson, "shortDescription", $$v)
              },
              expression: "lesson.shortDescription",
            },
          }),
          _c(
            "div",
            [
              _c("CInputCheckbox", {
                staticClass: "mb-3 mt-3",
                attrs: {
                  checked: _vm.lesson.has_homework,
                  label: _vm.$t("lk.training.homeWork"),
                  custom: "",
                },
                on: {
                  "update:checked": function ($event) {
                    return _vm.$set(_vm.lesson, "has_homework", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("tinymce", {
                staticClass: "form-group trainingMaterials__textarea",
                model: {
                  value: _vm.lesson.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.lesson, "description", $$v)
                  },
                  expression: "lesson.description",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "trainingMaterials__primary-video-block d-md-flex mb-4",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column trainingMaterials__file-left",
                },
                [
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.$t("lk.training.loadTitle")) + " "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center mt-2" },
                    [
                      _c(
                        "CLoadingButton",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            loading: _vm.isLessonLoading,
                            color: "success",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.openVideoList("primary")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  _vm.primaryVideo && _vm.primaryVideo.filename
                                    ? "lk.videoEncrypt.replaceVideo"
                                    : "lk.videoEncrypt.chooseVideo"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _vm.primaryVideo && _vm.primaryVideo.filename
                        ? _c("div", [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("lk.videoEncrypt.selectedVideo")
                                  ) +
                                  ": "
                              ),
                            ]),
                            _c(
                              "span",
                              { staticClass: "trainingMaterials__chat" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.primaryVideo.filename) + " "
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.removeVideo("primary")
                                  },
                                },
                              },
                              [
                                _c("CIcon", {
                                  staticClass: "trainingMaterials__close-icon",
                                  attrs: { width: "0.7rem", name: "cil-x" },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.primaryVideo
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "additionalMaterialCard__video-time-code-wrapper mt-3",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "additionalMaterialCard__video-time-code-block d-flex align-items-center",
                            },
                            [
                              _c("CTextarea", {
                                staticClass: "mb-0 w-100",
                                attrs: {
                                  label: _vm.$t("lk.videoEncrypt.timeCodes"),
                                  rows: "5",
                                },
                                model: {
                                  value: _vm.primaryVideoMarkers,
                                  callback: function ($$v) {
                                    _vm.primaryVideoMarkers = $$v
                                  },
                                  expression: "primaryVideoMarkers",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _c("div", { staticClass: "trainingMaterials__file-right" }, [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          _vm.lesson.chat
                            ? "lk.training.editChatFile"
                            : "lk.training.addChatFile"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "d-flex mt-2 align-items-center" },
                  [
                    _c(
                      "CLoadingButton",
                      {
                        staticClass: "mr-2",
                        attrs: {
                          loading: _vm.isLessonLoading,
                          color: "success",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.selectChatFile()
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                _vm.lesson.chat
                                  ? "lk.training.editChatFileBtn"
                                  : "lk.training.addChatFileBtn"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("span", { staticClass: "trainingMaterials__chat" }, [
                      _vm._v(_vm._s(_vm.lesson.chatFileName)),
                    ]),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "trainingMaterials__additional-materials" },
            [
              _c(
                "div",
                { staticClass: "trainingMaterials__materials-wrapper" },
                [
                  _c(
                    "div",
                    [
                      _vm.lesson.videos.length
                        ? _c("h3", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("lk.videoEncrypt.additionalMaterials")
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.lesson.videos, function (item, index) {
                        return _c("AdditionalMaterialCard", {
                          key: index,
                          attrs: {
                            primaryVideo: _vm.primaryVideo,
                            video: item,
                            index: index,
                          },
                          on: {
                            removeVideo: _vm.removeVideo,
                            removeVideoField: _vm.removeVideoField,
                            openVideoList: _vm.openVideoList,
                          },
                        })
                      }),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column justify-content-end trainingMaterials__bottom mt-3",
                },
                [
                  _c(
                    "div",
                    { staticClass: "trainingMaterials__bottom-wrapper" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center mb-2 trainingMaterials__bottom-addButton",
                          on: { click: _vm.addVideoField },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "trainingMaterials__add-video-button mr-2",
                            },
                            [_vm._v(" + ")]
                          ),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("lk.videoEncrypt.moreVideo")) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "CLoadingButtonCustom",
                        {
                          staticClass: "trainingMaterials__bottom-btn",
                          attrs: {
                            loading: _vm.isLessonLoading,
                            color: "primary",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.saveLesson.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("lk.training.btnSave")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm.showVideoList
        ? _c("VideoListModal", {
            attrs: {
              show: _vm.showVideoList,
              videos: _vm.lesson?.videos || [],
            },
            on: {
              selcetVideo: _vm.addVideo,
              "update:show": function ($event) {
                _vm.showVideoList = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }