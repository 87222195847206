<template>
  <CModal
    centered
    :show="show"
    @update:show="$emit('update:show', $event)"
    class="AddToNewModal"
  >
    <template #header-wrapper
      ><div class="AddToNewModal__header">
        <span>{{ $t("lk.training.addToNewModal.title") }}</span>
        <div @click="close" class="AddToNewModal__header-close">×</div>
      </div></template
    >
    <h3 class="AddToNewModal__desc">{{ $t("lk.training.addToNewModal.desc") }}</h3>
    <div class="AddToNewModal__btnBlock">
      <CButton
        @click="addNewMarker"
        :disabled="isLoading"
        class="shadow-none AddToNewModal__btn"
        color="success"
      >
        {{ $t("lk.training.addToNewModal.yes") }}
      </CButton>
      <CButton
        class="shadow-none AddToNewModal__btn"
        color="primary"
        @click="close"
      >
        {{ $t("lk.training.addToNewModal.no") }}
      </CButton>
    </div>

    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>
import axios from "axios";

export default {
  name: "AddToNewModal",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      curatorName: "",
      curatorTelegram: "",
      isLoading: false,
    };
  },
  methods: {
    close() {
      this.curatorName = ''
      this.curatorTelegram = ''
      this.$emit('close')
    },
    addNewMarker() {
        if(!this.isLoading) {
            this.isLoading = true
            axios.post(`api/v2/lessons/${this.id}/new`).then(() => {
                this.close()
            }).finally(() => {
              this.isLoading = false
            })
        }
    }
  }
};
</script>

<style lang="scss" scoped>
.ccard {
  margin-bottom: 0;
  max-height: calc(100vh - 300px);
  max-height: calc(100dvh - 300px);

  @include media-breakpoint-down(xs) {
    .ccard-body {
      padding: 1rem 1.75rem;
    }
  }

  @include media-breakpoint-up(sm) {
    .ccard-body {
      padding: 1.25rem 1.75rem;
    }
  }

  @include media-breakpoint-up(xl) {
    margin: 0;
  }
}

.AddToNewModal {

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    padding-top: 16px;
    font-size: 21px;
    font-weight: 500;

    &-close {
      font-size: 46px;
      font-weight: 100;
      line-height: 10px;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $danger;
      }
    }
  }

  &__btn {
    width: 100%;
    height: 44px;
  }

  &__desc {
    font-size: 14px;
  }

  &__btnBlock {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }
}
</style>
