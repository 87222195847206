var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c("AddToNewModal", {
        attrs: { show: _vm.showModal, id: _vm.lastUpdatedLesson },
        on: {
          close: function ($event) {
            _vm.showModal = false
          },
        },
      }),
      _vm.loadingMaterials
        ? _c("CSpinner", { attrs: { color: "primary", size: "lg" } })
        : _vm._e(),
      [
        _vm._l(_vm.activeMaterials, function (lesson, index) {
          return _c("EditTrainingMaterialCard", {
            key: index,
            attrs: {
              "event-id": _vm.eventId,
              lesson: lesson,
              loadingMaterials: _vm.loadingMaterials,
            },
            on: { updateLessons: _vm.updateLessons },
          })
        }),
        _c(
          "CLoadingButton",
          {
            attrs: { color: "primary", loading: _vm.loadingCreateLesson },
            nativeOn: {
              click: function ($event) {
                return _vm.addLesson.apply(null, arguments)
              },
            },
          },
          [_vm._v(" Добавить урок ")]
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }