<template>
  <div class="w-100">
    <CSpinner v-if="loading" color="primary" size="lg" />
    <div class="trainingMaterials pb-3" v-else>
      <CCard
        color="second-card-bg"
        class="trainingMaterials__block"
      >
        <CInput
          v-if="isAdmin"
          :label="$t('lk.training.tg_group_url')"
          type="text"
          class="pl-0"
          v-model="course.tg_group_url"
        />
        <CInput
          v-if="isAdmin"
          :label="$t('lk.training.route_check')"
          :disabled="true"
          type="text"
          class="pl-0"
          :value="route_check ? 'POST ' + route_check : ''"
        />
        <CInput
          v-if="isAdmin"
          :label="$t('lk.training.route_approved')"
          :disabled="true"
          type="text"
          class="pl-0"
          :value="route_check ? 'PUT ' + route_approved : ''"
        />
        <div v-if="isAdmin && course.managers" class="trainingPreview__managers">
          <h3>
            {{ $t('lk.training.managersTraining') }}
            <CLoadingButtonCustom
              color="primary"
              @click.native="showManagerModal = true"
            >
              {{ $t('lk.training.addManager') }}
            </CLoadingButtonCustom>
          </h3>
          <div v-for="(manager, managerI) in course.managers" style="margin: 1rem">
            {{ manager.title }}
            <CButton
              class="btn-sm shadow-none inline ml-3"
              color="outline-danger"
              @click="course.managers.splice(managerI, 1)"
            >
              <CIcon name="cil-x" />
            </CButton>
          </div>
        </div>
        <div class="trainingPreview__authors">
          <h3>
            {{ $t('lk.training.authorTraining') }}
            <CLoadingButtonCustom
              color="primary"
              @click.native="showAuthorModal = true"
            >
              {{ course.author ? $t('lk.training.replaceAuthor') : $t('lk.training.addAuthor') }}
            </CLoadingButtonCustom>
          </h3>
          <div v-if="course.author" class="d-flex align-items-center" style="margin: 1rem">
            <small-author
              v-if="course.author"
              class="trainingMaterials__item-author"
              :author="course.author"
            />
            <CButton
              class="btn-sm shadow-none inline ml-3"
              color="outline-danger"
              @click="course.author = null"
            >
              <CIcon name="cil-x" />
            </CButton>
          </div>
        </div>
        <CLoadingButtonCustom
          color="primary"
          class="cbtn mt-4 trainingMaterials__btn"
          :loading="loadBtn"
          :disabled="loadBtn"
          @click.native="editPreviewData"
        >
          {{ $t('lk.training.btnSaveCourse') }}
        </CLoadingButtonCustom>
      </CCard>
    </div>

    <ManagerModal
      @close="showManagerModal = false"
      @success="handleUpdateManagerSuccess"
      :show.sync="showManagerModal"
      :eventId="eventId"
    />
    <AuthorModal
      @close="showAuthorModal = false"
      @success="course.author = $event"
      :show.sync="showAuthorModal"
      :eventId="eventId"
    />
  </div>
</template>

<script>
import axios from 'axios'
import { DatePicker } from 'v-calendar'
import moment from 'moment'
import Tinymce from "@/components/common/Tinymce"
import ManagerModal from '@/components/training/ManagerModal.vue'
import VSelect from 'vue-select'
import AuthorModal from '@/components/training/AuthorModal.vue'
import Author from '@/assets/js/Author.class';
import SmallAuthor from '@/components/training/SmallAuthor.vue';

export default {
  name: 'TrainingSettings',
  components: {
    AuthorModal,
    ManagerModal,
    'v-date-picker': DatePicker,
    Tinymce,
    VSelect,
    SmallAuthor,
  },
  props: {
    eventId: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      baseUrl: axios.defaults.baseURL,
      loading: false,
      loadBtn: false,
      showModal: false,
      showManagerModal: false,
      showAuthorModal: false,
      educationFlows: [],
      accessType: 'all',
      accessInitType: 'all',
      isAdmin: false,
      route_check: '',
      route_approved: '',
      course: {
        course_title: '',
        course_cover_photo: '',
        course_wide_photo: '',
        course_description: '',
        tg_group_url: '',
        slug: '',
        availableToBuyFrom: null,
        availableForTrainingFrom: null,
        published: null,
        links: [],
        syncLinks: 1,
        managers: null,
        author: null,
        accessFlows: []
      },
      files: {
        course_cover_photo: null,
        course_wide_photo: null,
      },
    }
  },
  mounted() {
    this.getPreviewData()
  },
  computed: {
    optionsFlows() {
      return [...this.educationFlows].sort((a, b) => a?.id > b?.id ? 1 : -1)
    }
  },
  methods: {
    onChangeInputFile(field, files) {
      if (!(field in this.files)) return

      if (files.length > 0) {
        this.files[field] = files[0]
      }
    },
    getPreviewData() {
      this.loading = true;

      axios.get(`${this.baseUrl}/api/v2/courses/${this.eventId}`, {
        params: {
          with: ['authors']
        }
      })
        .then(res => {
          this.setCourseData(res?.data?.data)
        })
        .catch((e) => {
          this.$toastr.error(this.$t('lk.training.errorLoadData'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    editPreviewData() {
      this.loadBtn = true;

      let formData = new FormData()
      for(let key in this.course) {
        if(key === 'availableToBuyFrom' || key === 'availableForTrainingFrom'){
          if(this.course[key]){
            formData.append(key, moment(this.course[key]).format('YYYY-MM-DD HH:mm:ss'))
          }else{
            formData.append(key, '')
          }
        }else if (key === 'published') {
          formData.append(key, Boolean(this.course[key]) ? 1 : 0)
        }else if (key === 'managers' && this.course[key] !== null) {
          if(this.course[key].length){
            this.course[key].forEach(manager => {
              formData.append('managers[]', manager.id)
            })
          }else{
            formData.append('managers', '')
          }
        }else if(key === 'links'){
          this.course[key].forEach((itemLink, i) => {
            if(itemLink.id){
              formData.append(`links[${i}][id]`, itemLink.id)
            }
            formData.append(`links[${i}][title]`, itemLink.title)
            formData.append(`links[${i}][link]`, itemLink.link)
          })
        }else if(key === 'accessFlows'){
          if(this.course[key].length){
            formData.append('access', 'flows')
            this.course[key].forEach(item => {
              formData.append('accessFlows[]', item.id || '')
            })
          }else{
            formData.append('access', 'all')
            formData.append('accessFlows', '')
          }
        }else if(key === 'author'){
          if(this.course[key]?.id){
            formData.append('authors[]', this.course[key].id)
          }else if(this.course[key]?.user?.id){
            formData.append('author_user_id', this.course[key].user.id)
          }else{
            formData.append('authors', '')
          }
        }else if (!(`${key}` in this.files)) {
          formData.append(key, this.course[key] ? this.course[key] : '')
        }
      }

      for(let key in this.files) {
        if (this.files[key]) {
          formData.append(key, this.files[key])
        }
      }
      axios.post(
        `${this.baseUrl}/api/v2/courses/${this.eventId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          params: {
            with: ['authors']
          }
        })
        .then(res => {
          this.$toastr.success(this.$t('lk.training.successSave'))
          this.setCourseData(res?.data?.data)
        })
        .catch((e) => {
          this.$toastr.error(this.$t('lk.training.errorSave'))
        })
        .finally(() => {
          this.loadBtn = false
        })
    },
    managerTitle(user){
      return user.id + '. ' + user.name + ' (' + user.email + (user.tg_nick ? ', ' + '@' + user.tg_nick : '') + ')'
    },
    setCourseData(data){
      if(!data){
        return
      }
      this.educationFlows = data.educationFlows || []
      this.isAdmin = data.isAdmin || false
      this.route_check = data.route_check || ''
      this.route_approved = data.route_approved || ''
      for (let key in this.course) {
        if(key === 'author'){
          if(data['authors'] && data['authors'][0]?.id){
            this.course['author'] = new Author(data['authors'][0])
          }else{
            this.course['author'] = null
          }
        }
        if (`${key}` in data) {
          this.course[key] = data[key];
          if(key === 'managers'){
            this.course[key].forEach(item => {
              item.title = this.managerTitle(item)
              item.sync = true
            })
          }else if(key === 'accessFlows'){
            this.course[key] = this.course[key].map(item => {
              return this.educationFlows.find(el => el.id === item) || {
                id: item,
                name: item
              }
            })
          }
        }
      }
      this.accessType = data.accessType || (this.course.accessFlows.length ? 'flows' : 'all')
      this.accessInitType = this.course.accessFlows.length === 0 ? this.accessType : 'all'
    },
    handleUpdateManagerSuccess(user){
      if(this.course.managers.some(item => item.id === user.id)){
        return
      }
      user.title = this.managerTitle(user)
      this.course.managers.push(user)
    },
    getFlowsOptionName(option) {
      return option?.id === 'all' ? 'All' : (option?.id ? option.id + '. ' : '') + (option?.name || option?.id);
    },
    flowsCustomFilter(options, search) {
      return options.filter(option =>{
        return (option.name && option.name.toLowerCase().includes(search.toLowerCase())) ||
          (option.id && option.id.toString().includes(search))
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.trainingPreview{
  &__managers, &__authors{
    font-size: 1.5rem;
    margin-bottom: 3rem;
    margin-top: 3rem;
  }
}
.trainingMaterials {
  width: 100%;
  &__block {
    width: 100%;
    padding: 16px;
    @include media-breakpoint-up(md) {
      padding: 22px 24px;
    }
    @include media-breakpoint-up(lg) {
      padding: 24px 32px;
    }
  }
  &__img {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 20rem;
    }
    img {
      display: block;
      width: 100%;
    }
  }
  &__btn {
    width: fit-content;
  }
  &__header__btns {
    display: flex;
    @include media-breakpoint-up(sm) {
      display: inline-flex;
      margin-top: 27px;
    }
  }
  &__item-author{
    padding: 10px;
  }
}
.v-select-flows {

  /deep/ .vs__dropdown-toggle {
    padding: 10px 6px;
    border: 1px solid #5A6570;
    border-radius: 4px;
  }
  /deep/ .vs__actions{
    display: none;
  }
  /deep/ .vs__selected-options{
    overflow: auto;
    max-height: 120px;
  }

  /deep/ .vs__selected {
    padding: 3px 14px;
    background: var(--primary) !important;
    color: white;
    font-size: 14px;
    line-height: 17px;
    border-radius: 13px;
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    gap: 5px;

    .badge {
    }
  }

  /deep/ .vs__deselect {
    fill: white;
  }

  /deep/ .vs__search {
    color: var(--white);
  }

  /deep/ .vs__dropdown-menu {
    display: flex;
    flex-wrap: wrap;
    background: var(--v-select-bg);
    gap: 8px;
    padding: 20px 13px;
  }

  /deep/ .vs__dropdown-option--selected {
    background: var(--primary) !important;
    border-color: var(--primary) !important;
  }

  /deep/ .vs__dropdown-option {
    padding: 3px 14px;
    background: transparent;
    border: 1px solid var(--white);
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: white;
    border-radius: 13px;

    &--selected {
      .badge {
        color: white !important;
      }
    }

    .badge {
      font-size: 14px !important;
      line-height: 17px !important;
      color: var(--white);
      padding: 0;
    }
  }
  /deep/ .vs__deselect {
    position: relative;

    &:before,
    &:after {
      content: "";
      width: 9px;
      height: 1px;
      background: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }

    &:before {
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
  }
}
</style>
