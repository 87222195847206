var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "timeCodeItem d-flex justify-content-between mb-3" },
    [
      _c(
        "span",
        {
          on: {
            click: function ($event) {
              return _vm.$emit("removeMarker")
            },
          },
        },
        [
          _c("CIcon", {
            staticClass: "timeCodeItem__deleteIcon",
            attrs: { width: "0.7rem", name: "cil-x" },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group timeCodeItem__time mb-0" },
        [
          _c("the-mask", {
            staticClass: "form-control",
            attrs: {
              placeholder: "hh:mm:ss",
              masked: true,
              mask: ["##:##:##"],
            },
            model: {
              value: _vm.marker.time,
              callback: function ($$v) {
                _vm.$set(_vm.marker, "time", $$v)
              },
              expression: "marker.time",
            },
          }),
        ],
        1
      ),
      _c("CInput", {
        staticClass: "timeCodeItem__text mb-0",
        attrs: { type: "text" },
        model: {
          value: _vm.marker.text,
          callback: function ($$v) {
            _vm.$set(_vm.marker, "text", $$v)
          },
          expression: "marker.text",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }