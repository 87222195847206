<template>
  <CModal centered :show="show" @update:show="$emit('update:show', $event)" class="managerModal">
    <template #header-wrapper
      ><div class="managerModal__header">
        <span>{{ $t('lk.training.managerModal.titleAdd') }}</span>
        <div @click="close" class="managerModal__header-close">×</div>
      </div></template
    >

    <template #body-wrapper>
      <div class="managerModal__body">
        <v-select-input
          v-model="user"
          :options="userList"
          :clearable="false"
          :get-option-label="(option) => option.email"
          :placeholder="$t('lk.training.managerModal.emailPlaceholder')"
          @search="onSearch"
        >
          <template #prepend>
            <CIcon name="mail" />
          </template>
          <template #no-options>
            {{ $t('general.emptySearch') }}
          </template>
        </v-select-input>
        <div class="managerModal__btnBlock">
          <CButton
            @click="saveManager"
            class="shadow-none managerModal__btn"
            :disabled="!user"
            color="success"
          >
            {{ $t('lk.training.managerModal.btnAdd') }}
          </CButton>
          <CButton class="shadow-none managerModal__btn" color="primary" @click="close">
            {{ $t('general.cancel') }}
          </CButton>
        </div>
      </div>
      <CElementCover v-if="isLoading" />
    </template>

    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>
import axios from 'axios'
import debounce from 'lodash/debounce'
import VSelectInput from '@/components/VSelectInput'

export default {
  name: 'ManagerModal',
  components: {
    VSelectInput,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    searchDebounce: {
      type: Number,
      default: 300,
    },
    eventId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      userList: [],
      user: null,
    }
  },
  methods: {
    saveManager() {
      if (!this.isLoading && this.user) {
        this.$toastr.success(this.$t('lk.training.managerModal.completeAdd'))
        this.$emit('success', this.user)
        this.close()
      }
    },

    onSearch(search, loading) {
      if (search.length && search.length >= 3) {
        loading(true)
        this.search(loading, search, this)
      }
    },

    search: debounce((loading, search, vm) => {
      axios
        .get('api/v1/users/autocomplete', { params: { term: search } })
        .then((res) => {
          vm.userList = res.data.data
        })
        .finally(() => {
          loading(false)
        })
    }, 300),

    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.managerModal {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 0;
    font-size: 21px;
    line-height: 1;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      padding: 24px 32px 0;
    }

    &-close {
      font-size: 23px;
      font-weight: 100;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $danger;
      }
    }
  }

  &__btn {
    width: 100%;
    height: 44px;
  }

  &__btnBlock {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: 20px;
    }
  }

  &__body {
    padding: 20px 16px 20px;

    @include media-breakpoint-up(md) {
      padding: 24px 32px 32px;
    }
  }
}
</style>
