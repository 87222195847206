var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      staticClass: "AddToNewModal",
      attrs: { centered: "", show: _vm.show },
      on: {
        "update:show": function ($event) {
          return _vm.$emit("update:show", $event)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "header-wrapper",
          fn: function () {
            return [
              _c("div", { staticClass: "AddToNewModal__header" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("lk.training.addToNewModal.title"))),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "AddToNewModal__header-close",
                    on: { click: _vm.close },
                  },
                  [_vm._v("×")]
                ),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "footer-wrapper",
          fn: function () {
            return [_c("span")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("h3", { staticClass: "AddToNewModal__desc" }, [
        _vm._v(_vm._s(_vm.$t("lk.training.addToNewModal.desc"))),
      ]),
      _c(
        "div",
        { staticClass: "AddToNewModal__btnBlock" },
        [
          _c(
            "CButton",
            {
              staticClass: "shadow-none AddToNewModal__btn",
              attrs: { disabled: _vm.isLoading, color: "success" },
              on: { click: _vm.addNewMarker },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("lk.training.addToNewModal.yes")) + " "
              ),
            ]
          ),
          _c(
            "CButton",
            {
              staticClass: "shadow-none AddToNewModal__btn",
              attrs: { color: "primary" },
              on: { click: _vm.close },
            },
            [_vm._v(" " + _vm._s(_vm.$t("lk.training.addToNewModal.no")) + " ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }