<template>
  <div class="studentTable">
    <div class="studentTable__filters">
      <span class="studentTable__filters-title h4">
        {{$t('general.filters')}}
      </span>
      <div class="studentTable__filters-input-wrappers mt-2">
        <CInput
        :value="searchKey"
        placeholder="E-mail"
        @input="debouncedSearch"
        />
        <CSelect
        :options="tariffOptions"
        :value.sync="filterTariff"
        @change="filterTariffHandler"
        />
      </div>
    </div>
    <AppointCurratorModal
      @close="showAppointCuratorModal = false"
      @success="handleSuccess"
      :show.sync="showAppointCuratorModal"
      :eventId="eventId"
      :selectedCuratorId="selectedCuratorId"
      :selectedStudentIds="selectedStudentIds"
    />
    <DeleteStudentModal
      @close="showDeleteStudentModal = false"
      @success="handleSuccess"
      :show.sync="showDeleteStudentModal"
      :eventId="eventId"
      :selectedStudentIds="selectedStudentIds"
    />
    <EditFinishDateModal
      @close="showEditFinishDateModal = false"
      @success="handleSuccess"
      :show.sync="showEditFinishDateModal"
      v-if="showEditFinishDateModal"
      :eventId="eventId"
      :currentStudent="currentStudent"
    />
    <EditTariffModal
      @close="showEditTariffModal = false"
      @success="handleSuccess"
      :show.sync="showEditTariffModal"
      v-if="showEditTariffModal"
      :eventId="eventId"
      :currentStudent="currentStudent"
    />
    <AddStudentUserModal
      @close="showAddStudentUserModal = false"
      @success="handleSuccess"
      :show.sync="showAddStudentUserModal"
      v-if="showAddStudentUserModal"
      :eventId="eventId"
      :currentStudent="currentStudent"
    />

    <div class="studentTable__header">
      <span class="mb-0 mr-3" color="base-card-bg">
        <span class="studentTable__header__white">
          {{ $tc('lk.studentTable.selected', selectedStudentIdsLength) }}
        </span>
        {{ selectedStudentIdsLength }}
        {{ $tc('lk.studentTable.students', selectedStudentIdsLength) }}
      </span>
      <CLoadingButtonCustom
        class="studentTable__header-btn"
        color="primary"
        @click.native="showAddStudentUserModal = true"
      >
        {{ $t('lk.training.addStudentUser.btnText') }}
      </CLoadingButtonCustom>
      <CLoadingButtonCustom
        class="ml-auto studentTable__header-btn"
        color="sub-btn"
        @click.native="exportToExcel()"
        >
        {{ $t('lk.studentTable.export') }}
      </CLoadingButtonCustom>
      <template v-if="selectedStudentIdsLength">
        <CLoadingButtonCustom
          class="studentTable__header-btn"
          color="primary"
          @click.native="appointManyStudents"
          >{{ $t('lk.studentTable.appointCurators') }}</CLoadingButtonCustom
        >
        <CLoadingButtonCustom
          class="studentTable__header-btn"
          color="danger"
          @click.native="showDeleteStudentModal = true"
          >{{ $t('lk.studentTable.delete') }}</CLoadingButtonCustom
        >
      </template>
    </div>
    <CCard color="second-card-bg" class="shadow-none studentTable__card">
      <CCardBody class="h-100 d-flex flex-column">

        <div class="table-wrap">
          <table class="table mb-0 studentTable__table">
            <thead>
              <tr>
                <th>
                  <CInputCheckbox
                    custom
                    ref="checkboxAll"
                    color="primary-contrast"
                    @change="selectAll"
                  />
                </th>
                <th
                v-for="(field, i) in fields"
                :key="i"
                @click="field.sortBy ? sortTable(field.sortBy) : null"
                :class="field._classes">
                  {{ field.label }}
                  <span
                  v-if="field.sortBy == orderBy"
                  :key="sortDir">
                  {{sortDir == 'DESC' ? '▼' : '▲'}}
                </span>
                </th>
              </tr>
            </thead>

            <tbody v-if="tableData.length">
              <tr v-for="(item, i) in tableData" :key="item.id + '_' + i">
                <td>
                  <CInputCheckbox
                    custom
                    :key="i"
                    color="primary-contrast"
                    ref="checkboxTable"
                    @change="handleCheckbox(item, $event.srcElement.checked)"
                    :checked="selectedStudentIds.includes(item.id)"
                  />
                </td>
                <td>{{ item.created_at | formatDate('DD.MM.YYYY HH:mm') }}</td>
                <td>{{ item.user.name }}</td>
                <td>{{ item.user.email }}</td>
                <td><a target="_blank" :href="`https://t.me/${item.user.tg_nick}`">{{ item.user.tg_nick }}</a></td>
                <td>
                    <CIcon
                      v-if="item.enter_tg_channel"
                      name="check"
                    /></td>
                <td>
                  <span
                    @click="editTariff(item)"
                    class="table-link table-link-hover">
                    {{ item.tariff?.name[$store.state.settings.global.locale] }}
                  </span>
                </td>
                <td>
                  <template v-if="item.finished_at">
                    <span
                    @click="editFinishDate(item)"
                    class="table-link table-link-hover">
                      {{ item.finished_at | formatDate('DD.MM.YYYY') }}
                    </span>
                  </template>
                  <template v-else>
                    <span>
                      -
                    </span>
                  </template>
                </td>
                <td>{{item?.education_flows?.[0]?.flow?.name}}</td>
                <td>
                  <div class="studentTable__hasMentorshipIcon">
                    <CIcon
                      v-if="item.has_mentor"
                      name="check"
                    />
                  </div>
                </td>
                <td>
                  <span @click="appointOneStudent(item)" class="table-link table-link-hover">
                    {{
                      item.curator
                        ? item.curator.name || item.curator.user.email
                        : $t('lk.studentTable.appoint')
                    }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <CElementCover v-if="isLoading" />
          <span
          class="studentTable__placeholder"
          v-if="!tableData.length && !isLoading">
            {{ $t('general.emptySearch') }}
          </span>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import AppointCurratorModal from './AppointCurratorModal'
import EditFinishDateModal from './EditFinishDateModal'
import EditTariffModal from './EditTariffModal'
import DeleteStudentModal from './DeleteStudentModal'
import AddStudentUserModal from './AddStudentUserModal'
import axios from 'axios'
import moment from 'moment'
import debounce from "lodash/debounce";

export default {
  name: 'TrainingStudentTable',
  components: {
    AppointCurratorModal,
    DeleteStudentModal,
    EditFinishDateModal,
    EditTariffModal,
    AddStudentUserModal,
  },
  props: {
    // featured variable event id
    eventId: {
      type: Number,
      required: true
    },
  },

  data() {
    return {
      searchKey:'',
      currentStudent: null,
      showEditFinishDateModal: false,
      showEditTariffModal: false,
      showAddStudentUserModal: false,
      orderBy: 'created_at',
      sortDir: "DESC",
      isLoading: false,
      showAppointCuratorModal: false,
      showDeleteStudentModal: false,
      selectedCuratorId: null,
      selectedStudentIds: [],
      tableData: [],
      fields: [
        {
          key: 'date',
          _classes: 'align-middle tableHover',
          label: this.$t('lk.training.studentTable.dateBuy'),
          sortBy: 'created_at'
        },
        {
          key: 'user_name',
          _classes: 'align-middle tableHover',
          label: this.$t('lk.training.studentTable.name'),
          sortBy: 'name'
        },
        {
          key: 'user_email',
          _classes: 'align-middle tableHover',
          label: this.$t('lk.training.studentTable.email'),
          sortBy: 'email'
        },
        {
          key: 'tg_name',
          _classes: 'align-middle',
          label: this.$t('lk.training.studentTable.tgName'),
        },
        {
          key: 'chat_approved',
          _classes: 'align-middle tableHover',
          label: this.$t('lk.training.studentTable.chatApproved'),
          sortBy: 'new_tg_enabled'
        },
        {
          key: 'tariff',
          _classes: 'align-middle tableHover',
          label: this.$t('lk.training.studentTable.tariff'),
          sortBy: 'tariff'
        },
        {
          key: 'finished_at',
          _classes: 'align-middle tableHover',
          label: this.$t('lk.training.finish_at'),
          sortBy: 'finished_at'
        },
        {
          key: 'flow',
          _classes: 'align-middle tableHover',
          label: this.$t('lk.training.studentTable.flow'),
        },
        {
          key: 'mentorship',
          _classes: 'align-middle tableHover',
          label: 'Mentorship',
        },
        {
          key: 'curator',
          _classes: 'align-middle',
          label: this.$t('lk.training.studentTable.curator'),
        },
      ],
      filterTariff: null,
      tariffOptions: [
        {
          label: 'All',
          value: null
        },
        {
          label: 'All-IN',
          value: 'all_in_edu'
        },
        {
          label: 'WEB3',
          value: 'web3_edu'
        },
        {
          label: 'Crypto Basics',
          value: 'crypto_basics_edu'
        },
        {
          label: 'STANDARD',
          value: 'standard_edu'
        }
      ]
    }
  },

  created() {
    this.getStudets({orderBy: this.orderBy})
  },

  computed: {
    selectedStudentIdsLength() {
      return this.selectedStudentIds.length
    },
  },

  methods: {
    filterTariffHandler(){
      this.getStudets({
        orderBy: this.orderBy,
        dir: this.sortDir,
        filter: {
          email: this.searchKey,
          edu_tariff: this.filterTariff
        }
      });
    },
    debouncedSearch: debounce(function(value) {
      this.searchKey = value
      this.getStudets({
        orderBy: this.orderBy,
        dir: this.sortDir,
        filter: {
          email: this.searchKey,
          edu_tariff: this.filterTariff
        }
      });
    }, 500),
    sortTable(sortBy) {
      if(sortBy != this.orderBy) {
        this.orderBy = sortBy
        this.sortDir = "DESC"
      } else {
        this.sortDir == "DESC" ? this.sortDir = "ASC" : this.sortDir = "DESC"
      }
      this.getStudets({
        orderBy: this.orderBy,
        dir: this.sortDir,
        filter: {
          email: this.searchKey,
          edu_tariff: this.filterTariff
        }
      })
    },
    handleCheckbox(student, checked) {
      if (checked && !this.selectedStudentIds.includes(student.id)) {
        this.selectedStudentIds.push(student.id)
      } else {
        const index = this.selectedStudentIds.indexOf(student.id)
        if (index !== -1) {
          this.selectedStudentIds.splice(index, 1)
        }
      }
    },

    selectAll(e) {
      if (e.srcElement.checked) {
        this.tableData.forEach((student) => {
          if (!this.selectedStudentIds.includes(student.id)) {
            this.selectedStudentIds.push(student.id)
          }
        })
      } else {
        this.resetSelection()
      }
    },

    resetSelection() {
      this.selectedStudentIds = []
    },
    editTariff(student){
      this.currentStudent = student
      this.showEditTariffModal = true
    },
    editFinishDate(student){
      this.currentStudent = student
      this.showEditFinishDateModal = true
    },
    appointOneStudent(student) {
      this.resetSelection()
      this.selectedStudentIds.push(student.id)
      this.selectedCuratorId = student.curator?.id
      this.showAppointCuratorModal = true
    },

    appointManyStudents() {
      if (this.selectedStudentIds.length) {
        this.selectedCuratorId = null
        this.showAppointCuratorModal = true
      }
    },

    exportToExcel() {
      if (!this.isLoading) {
        this.isLoading = true
        axios
          .get(`/api/v2/events/${this.eventId}/users/export`, { responseType: 'blob' })
          .then((data) => {
            if (data.data) {
              let blobUrl = URL.createObjectURL(data.data)
              let linkTag = document.createElement('a')
              linkTag.href = blobUrl
              linkTag.download = `export_event_${this.eventId}_${moment().format('DD_MM_YYYY')}.xls`
              linkTag.click()
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },

    resetFilters(){
      this.searchKey = ''
      this.filterTariff = null
    },

    handleSuccess() {
      this.resetSelection()
      this.getStudets({orderBy: this.orderBy})
    },

    getStudets(params) {
      this.isLoading = true
      axios
        .get(`/api/v2/events/${this.eventId}/users`, {params: {
          ...params,
          'filter[email]': params?.filter?.email || null,
          'filter[edu_tariff]': params?.filter?.edu_tariff || null,
        }})
        .then(({ data }) => {
          this.tableData = [...data.data]
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.ccard-body {
  overflow: hidden;
}

.table {
  position: relative;

  thead tr th {
    background: var(--second-card-bg);
    position: sticky;
    top: -1px;
    z-index: 1;
  }
}

.studentTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__hasMentorshipIcon{
    display: flex;
    justify-content: center;
  }
  &__filters-input-wrappers{
    display: flex;
    gap: 10px;
  }
  .table-wrap {
    overflow: auto;
    width: 100%;
    flex-grow: 1;
    height: 500px;
  }
  &__placeholder{
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid var(--subscribe-card-bg);
    color: #20a8d8;
    padding: 12px 24px;
    align-items: flex-start;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
    }

    &__white {
      color: var(--white);
    }

    &-btn {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }
  }

  &__card {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-bottom: 0;
    height: 75%;
  }

  &__table {
    tbody {
      max-height: 225px;
    }

    tbody tr td {
      &:nth-child(1) {
        width: 10px;
      }

      &:nth-child(2) {
        width: 14%;
      }

      &:nth-child(3) {
        width: 18%;
      }

      &:nth-child(4) {
        width: 20.9%;
      }

      &:nth-child(5) {
        width: 15%;
      }

      &:nth-child(6) {
        width: 8%;
      }

      &:nth-child(7) {
        width: 15%;
      }

      &:nth-child(8) {
        width: 9.2%;
      }
    }
  }

  .modal-header {
    border-bottom: none;
  }
}

.table-link {
  color: var(--white);
}

.table-link-hover {
  color: var(--primary);
  cursor: pointer;

  &:hover {
    color: var(--white);
    text-decoration: underline;
  }
}

.tableHover:hover {
    cursor: pointer;
    color: var(--white);
    text-decoration: underline;
  }
</style>
