export const secToHHMMSS = (timeInSeconds)=>{
  const sec = parseInt(timeInSeconds, 10);
    let hours   = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - (hours * 3600)) / 60);
    let seconds = sec - (hours * 3600) - (minutes * 60);
    if (hours   < 10) {hours   = `0${hours}`}
    if (minutes < 10) {minutes = `0${minutes}`}
    if (seconds < 10) {seconds = `0${seconds}`}
    if(+hours){
      return `${hours}:${minutes}:${seconds}`
    }
    return `${minutes}:${seconds}`;
}

export const hhmmssToSec = (timeSring)=>{
  let newTime
  const timeArray = timeSring.split(':');
  if(timeArray.length === 1){
    newTime = (+timeArray[0]);
  }else if(timeArray.length === 2){
    newTime = (+timeArray[0]) * 60  + (+timeArray[1]);
  }else if(timeArray.length === 3){
    newTime = (+timeArray[0]) * 60 * 60 + (+timeArray[1]) * 60 + (+timeArray[2]);
  }
  return newTime
}
