<template>
  <CModal centered :show="show" @update:show="$emit('update:show', $event)" class="addCuratorModal">
    <template #header-wrapper>
      <div class="addCuratorModal__header">
        <span>{{ $t('lk.training.appointCuratorModal.title') }}</span>
        <div @click="close" class="addCuratorModal__header-close">×</div>
      </div>
    </template>

    <template #body-wrapper>
      <div class="addCuratorModal__body">
        <CSelect
          :label="$t('lk.training.appointCuratorModal.select')"
          :placeholder="$t('lk.training.appointCuratorModal.selectPlaceholder')"
          :options="options"
          :value.sync="value"
        ></CSelect>

        <div class="addCuratorModal__btnBlock">
          <CButton
            @click="appointCurator"
            :disabled="!value"
            class="shadow-none addCuratorModal__btn"
            color="success"
          >
            {{ $t('lk.training.appointCuratorModal.btnAdd') }}
          </CButton>
          <CButton class="shadow-none addCuratorModal__btn" color="primary" @click="close">
            {{ $t('general.cancel') }}
          </CButton>
        </div>
      </div>
      <CElementCover v-if="isLoading" />
    </template>

    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AppointCurratorModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    eventId: {
      type: Number,
      required: true,
    },
    selectedCuratorId: {
      default: null,
    },
    selectedStudentIds: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      curators: [],
      value: null,
    }
  },

  created() {
    this.getCurators()
  },

  beforeUpdate() {
    this.value = this.selectedCuratorId
  },

  computed: {
    options() {
      return this.curators.map((item) => {
        let label = item.name || item.user.email
        if (item.tg_nick) {
          label += ` (@${item.tg_nick})`
        }
        return {
          value: item.id,
          label: label,
        }
      })
    },
  },

  methods: {
    appointCurator() {
      if (!this.isLoading && this.value) {
        this.isLoading = true
        const fd = new FormData()
        fd.append('curator_id', this.value)
        this.selectedStudentIds.forEach(student_id => fd.append('id[]', student_id))
        axios
          .post(`/api/v2/events/${this.eventId}/users`, fd)
          .then(() => {
            this.$toastr.success(this.$t('lk.training.appointCuratorModal.complete'))
            this.$emit('success')
            this.close()
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },

    getCurators() {
      if (!this.isLoading) {
        this.isLoading = true
        axios
          .get('/api/v1/curators', {
            params: {
              event_id: this.eventId,
            }
          })
          .then(({ data }) => {
            this.curators = [...data.data]
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },

    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.ccard {
  margin-bottom: 0;
  max-height: calc(100vh - 300px);
  max-height: calc(100dvh - 300px);

  @include media-breakpoint-down(xs) {
    .ccard-body {
      padding: 1rem 1.75rem;
    }
  }

  @include media-breakpoint-up(sm) {
    .ccard-body {
      padding: 1.25rem 1.75rem;
    }
  }

  @include media-breakpoint-up(xl) {
    margin: 0;
  }
}

.addCuratorModal {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 0;
    font-size: 21px;
    line-height: 1;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      padding: 24px 32px 0;
    }

    &-close {
      font-size: 23px;
      font-weight: 100;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $danger;
      }
    }
  }

  &__btn {
    width: 100%;
    height: 44px;
  }

  &__btnBlock {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: 20px;
    }
  }

  &__body {
    padding: 20px 16px 20px;

    @include media-breakpoint-up(md) {
      padding: 24px 32px 32px;
    }
  }
}
</style>
