<template>
  <div class="curatorTable">
    <CuratorModal
      @close="showCuratorModal = false"
      @success="handleUpdateSuccess"
      :show.sync="showCuratorModal"
      :curator="curatorToUpdate"
      :eventId="eventId"
    />
    <DeleteCuratorModal
      @close="showDeleteCuratorModal = false"
      @success="handleDeleteSuccess"
      :show.sync="showDeleteCuratorModal"
      :curator="curatorToDelete"
    />
    <div class="curatorTable__header">
      <span class="mb-0 mr-3" color="base-card-bg">
        <span class="curatorTable__header__white">
          {{ $tc('lk.curatorTable.view', tableDataLength) }}
        </span>
        {{ tableDataLength }}
        {{ $tc('lk.curatorTable.curators', tableDataLength) }}
      </span>
      <CLoadingButtonCustom
        class="ml-auto curatorTable__header-btn"
        color="primary"
        @click.native="spreadCurators"
      >
        {{ $t('lk.curatorTable.spreadCurators') }}
      </CLoadingButtonCustom>
      <CLoadingButtonCustom
        class="ml-auto curatorTable__header-btn"
        color="primary"
        @click.native="
          curatorToUpdate = {}
          showCuratorModal = true
        "
      >{{ $t('lk.curatorTable.addCurator') }}
      </CLoadingButtonCustom
      >
    </div>
    <CCard color="second-card-bg" class="shadow-none curatorTable__card">
      <CCardBody class="h-100 d-flex flex-column">
        <div class="table-wrap">
          <table class="table mb-0 curatorTable__table">
            <thead>
            <tr>
              <th></th>
              <th v-for="(field, i) in fields" :key="i" :class="field._classes">
                {{ field.label }}
              </th>
            </tr>
            </thead>

            <tbody v-for="(item, i) in tableData" :key="item.id" color="primary">
            <tr>
              <td>
                  <span
                    v-if="item.event_users?.length"
                    @click="openMoreInfo(i)"
                    :class="
                      openedRow == i
                        ? 'curatorTable__table__btn __active'
                        : 'curatorTable__table__btn'
                    "
                  >❭</span
                  >
                </td>
                <td>{{ item.created_at | formatDate }}</td>
                <td>{{ item.user ? item.user.email : '-' }}</td>
                <td>{{ item.name }}</td>
                <td>
                  <template v-if="item.user.tg_nick">
                    <a :href="renderTgLink(item.user.tg_nick)" target="_blank" class="text-primary">
                      @{{ item.user.tg_nick }}
                    </a>
                  </template>
                </td>
                <td>{{ item.event_users?.length }}</td>
                <td>
                  <CButtonGroup>
                    <CButton color="info" size="sm" :to="{name: 'edit-curator-author', params: {curator_id: item.id, back: $route}}">
                      <CIcon name="cilPencil"></CIcon>
                    </CButton>
                    <CButton color="danger" size="sm" @click="handleDeleteBtnClick(item.id)">
                      <CIcon name="cilDelete"></CIcon>
                    </CButton>
                  </CButtonGroup>
                </td>
              </tr>
              <tr>
                <td v-if="openedRow === i" colspan="7" class="curatorTable__table__td-subTable">
                  <table class="table mb-0 curatorTable__table__subTable">
                    <thead>
                      <tr>
                        <th v-for="(subField, i) in fieldsSubTable" :key="'subField' + i">
                          {{ subField.label }}
                        </th>
                      </tr>
                    </thead>
                    <tbody v-for="subItem in item.event_users" :key="subItem.id">
                      <tr>
                        <td>{{ subItem.user.name }}</td>
                        <td>{{ subItem.user.email }}</td>
                        <td><a target="_blank" :href="`https://t.me/${subItem.user.tg_nick}`">{{ subItem.user.tg_nick }}</a></td>
                        <td>{{ subItem.tariff?.name[$store.state.settings.global.locale] }}</td>
                        <td>{{ subItem?.education_flows?.[0]?.flow?.name }}</td>
                        <td>
                          <div class="studetTable__hasMentorshipIcon">
                            <CIcon
                              v-if="subItem.has_mentor"
                              name="check"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <CElementCover v-if="isLoading"/>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import CuratorModal from './CuratorModal'
import DeleteCuratorModal from './DeleteCuratorModal'
import axios from 'axios'

export default {
  name: 'TrainingCuratorTable',
  components: {
    CuratorModal,
    DeleteCuratorModal,
  },
  props: {
    // featured variable event id
    eventId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      showCuratorModal: false,
      showDeleteCuratorModal: false,
      curatorToUpdate: {},
      curatorToDelete: {},
      openedRow: null,
      tableData: [],
      fields: [
        {
          key: 'date',
          label: this.$t('lk.training.curatorTable.createdAt'),
        },
        {
          key: 'login',
          label: this.$t('lk.training.curatorTable.login'),
        },
        {
          key: 'name',
          label: this.$t('lk.training.curatorTable.name'),
        },
        {
          key: 'tg_nick',
          label: this.$t('lk.training.curatorTable.telegram'),
        },
        {
          key: 'event_users',
          label: this.$t('lk.training.curatorTable.count'),
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      fieldsSubTable: [
        {
          key: 'user_name',
          label: this.$t('lk.training.curatorTable.subTable.name'),
        },
        {
          key: 'user_email',
          label: this.$t('lk.training.curatorTable.subTable.email'),
        },
        {
          key: 'tg_name',
          label: this.$t('lk.training.curatorTable.subTable.tg'),
        },
        {
          key: 'tariff',
          label: this.$t('lk.training.curatorTable.subTable.tariff'),
        },
        {
          key: 'flow',
          label: this.$t('lk.training.studentTable.flow'),
        },
        {
          key: 'mentorship',
          label: 'Mentorship',
        },
      ],
    }
  },
  created() {
    this.$route.params.tab = '2'
    this.getCurators()
  },
  computed: {
    tableDataLength() {
      return this.tableData?.length
    },
  },
  methods: {
    openMoreInfo(i) {
      if (this.openedRow === i) {
        this.openedRow = null
      } else {
        this.openedRow = i
      }
    },

    spreadCurators() {
      this.isLoading = true

      axios.post(`/api/v2/events/${this.eventId}/curators-spread`)
        .then(() => {
          this.$toastr.success(this.$t('general.dataChanged'))
          this.getCurators()
        })
        .catch(() => {
          this.$toastr.error(this.$t('general.error'))
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    async getCurators() {
      this.isLoading = true

      await axios
        .get('/api/v1/curators', {
          params: {
            event_id: this.eventId,
          },
        })
        .then(({ data }) => {
          this.tableData = [...data.data]
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    findCuratorById(id) {
      return this.tableData.find((curator) => curator.id === id)
    },

    handleUpdateBtnClick(id) {
      this.curatorToUpdate = this.findCuratorById(id)
      this.showCuratorModal = true
    },

    handleDeleteBtnClick(id) {
      this.curatorToDelete = this.findCuratorById(id)
      this.showDeleteCuratorModal = true
    },

    handleUpdateClose() {
      this.showCuratorModal = false
      this.curatorToUpdate = {}
    },

    handleUpdateSuccess() {
      this.curatorToUpdate = {}
      this.getCurators()
    },

    handleDeleteSuccess() {
      this.curatorToDelete = {}
      this.getCurators()
    },

    renderTgLink(value) {
      return `//t.me/${value}`
    },
  },
}
</script>

<style lang="scss" scoped>
.ccard-body {
  overflow: hidden;
}

.table {
  position: relative;

  thead tr th {
    background: var(--second-card-bg);
    position: sticky;
    top: -1px;
    z-index: 1;
  }
}

.curatorTable {
  width: 100%;
  display: flex;
  flex-direction: column;

  .table-wrap {
    overflow: auto;
    width: 100%;
    flex-grow: 1;
    height: 500px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid var(--subscribe-card-bg);
    color: #20a8d8;
    padding: 12px 24px;
    align-items: flex-start;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
    }

    &-btn {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }

    &__white {
      color: var(--white);
    }
  }

  &__card {
    height: 90%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-bottom: 0;
  }

  .__active {
    transform: rotate(-90deg);
  }

  &__table {
    tbody {
      max-height: 225px;
    }

    &__td-subTable {
      padding: 0 27px;
      opacity: 0;
      animation: showSubTableAnim 0.5s forwards;
      transition-delay: 0.5s;
    }

    &__btn {
      display: inline-block;
      transform: rotate(90deg);
      font-stretch: ultra-condensed;
      line-height: 0px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: rgba(#2eb85c, 0.2);
      }
    }

    &__subTable {
      background: var(--third-card-bg);

      th {
        border-top: none;
      }
    }

    tbody tr td {
      &:nth-child(1) {
        width: 10px;
      }

      &:nth-child(2) {
        width: 33%;
      }

      &:nth-child(3) {
        width: 21%;
      }

      &:nth-child(4) {
        width: 21%;
      }

      &:nth-child(5) {
        width: 25%;
      }
    }
  }

  .modal-header {
    border-bottom: none;
  }
}

@keyframes showSubTableAnim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
