var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "studentTable" },
    [
      _c("div", { staticClass: "studentTable__filters" }, [
        _c("span", { staticClass: "studentTable__filters-title h4" }, [
          _vm._v(" " + _vm._s(_vm.$t("general.filters")) + " "),
        ]),
        _c(
          "div",
          { staticClass: "studentTable__filters-input-wrappers mt-2" },
          [
            _c("CInput", {
              attrs: { value: _vm.searchKey, placeholder: "E-mail" },
              on: { input: _vm.debouncedSearch },
            }),
            _c("CSelect", {
              attrs: { options: _vm.tariffOptions, value: _vm.filterTariff },
              on: {
                "update:value": function ($event) {
                  _vm.filterTariff = $event
                },
                change: _vm.filterTariffHandler,
              },
            }),
          ],
          1
        ),
      ]),
      _c("AppointCurratorModal", {
        attrs: {
          show: _vm.showAppointCuratorModal,
          eventId: _vm.eventId,
          selectedCuratorId: _vm.selectedCuratorId,
          selectedStudentIds: _vm.selectedStudentIds,
        },
        on: {
          close: function ($event) {
            _vm.showAppointCuratorModal = false
          },
          success: _vm.handleSuccess,
          "update:show": function ($event) {
            _vm.showAppointCuratorModal = $event
          },
        },
      }),
      _c("DeleteStudentModal", {
        attrs: {
          show: _vm.showDeleteStudentModal,
          eventId: _vm.eventId,
          selectedStudentIds: _vm.selectedStudentIds,
        },
        on: {
          close: function ($event) {
            _vm.showDeleteStudentModal = false
          },
          success: _vm.handleSuccess,
          "update:show": function ($event) {
            _vm.showDeleteStudentModal = $event
          },
        },
      }),
      _vm.showEditFinishDateModal
        ? _c("EditFinishDateModal", {
            attrs: {
              show: _vm.showEditFinishDateModal,
              eventId: _vm.eventId,
              currentStudent: _vm.currentStudent,
            },
            on: {
              close: function ($event) {
                _vm.showEditFinishDateModal = false
              },
              success: _vm.handleSuccess,
              "update:show": function ($event) {
                _vm.showEditFinishDateModal = $event
              },
            },
          })
        : _vm._e(),
      _vm.showEditTariffModal
        ? _c("EditTariffModal", {
            attrs: {
              show: _vm.showEditTariffModal,
              eventId: _vm.eventId,
              currentStudent: _vm.currentStudent,
            },
            on: {
              close: function ($event) {
                _vm.showEditTariffModal = false
              },
              success: _vm.handleSuccess,
              "update:show": function ($event) {
                _vm.showEditTariffModal = $event
              },
            },
          })
        : _vm._e(),
      _vm.showAddStudentUserModal
        ? _c("AddStudentUserModal", {
            attrs: {
              show: _vm.showAddStudentUserModal,
              eventId: _vm.eventId,
              currentStudent: _vm.currentStudent,
            },
            on: {
              close: function ($event) {
                _vm.showAddStudentUserModal = false
              },
              success: _vm.handleSuccess,
              "update:show": function ($event) {
                _vm.showAddStudentUserModal = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "studentTable__header" },
        [
          _c(
            "span",
            { staticClass: "mb-0 mr-3", attrs: { color: "base-card-bg" } },
            [
              _c("span", { staticClass: "studentTable__header__white" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$tc(
                        "lk.studentTable.selected",
                        _vm.selectedStudentIdsLength
                      )
                    ) +
                    " "
                ),
              ]),
              _vm._v(
                " " +
                  _vm._s(_vm.selectedStudentIdsLength) +
                  " " +
                  _vm._s(
                    _vm.$tc(
                      "lk.studentTable.students",
                      _vm.selectedStudentIdsLength
                    )
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "CLoadingButtonCustom",
            {
              staticClass: "studentTable__header-btn",
              attrs: { color: "primary" },
              nativeOn: {
                click: function ($event) {
                  _vm.showAddStudentUserModal = true
                },
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("lk.training.addStudentUser.btnText")) + " "
              ),
            ]
          ),
          _c(
            "CLoadingButtonCustom",
            {
              staticClass: "ml-auto studentTable__header-btn",
              attrs: { color: "sub-btn" },
              nativeOn: {
                click: function ($event) {
                  return _vm.exportToExcel()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("lk.studentTable.export")) + " ")]
          ),
          _vm.selectedStudentIdsLength
            ? [
                _c(
                  "CLoadingButtonCustom",
                  {
                    staticClass: "studentTable__header-btn",
                    attrs: { color: "primary" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.appointManyStudents.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("lk.studentTable.appointCurators")))]
                ),
                _c(
                  "CLoadingButtonCustom",
                  {
                    staticClass: "studentTable__header-btn",
                    attrs: { color: "danger" },
                    nativeOn: {
                      click: function ($event) {
                        _vm.showDeleteStudentModal = true
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("lk.studentTable.delete")))]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "CCard",
        {
          staticClass: "shadow-none studentTable__card",
          attrs: { color: "second-card-bg" },
        },
        [
          _c("CCardBody", { staticClass: "h-100 d-flex flex-column" }, [
            _c(
              "div",
              { staticClass: "table-wrap" },
              [
                _c("table", { staticClass: "table mb-0 studentTable__table" }, [
                  _c("thead", [
                    _c(
                      "tr",
                      [
                        _c(
                          "th",
                          [
                            _c("CInputCheckbox", {
                              ref: "checkboxAll",
                              attrs: { custom: "", color: "primary-contrast" },
                              on: { change: _vm.selectAll },
                            }),
                          ],
                          1
                        ),
                        _vm._l(_vm.fields, function (field, i) {
                          return _c(
                            "th",
                            {
                              key: i,
                              class: field._classes,
                              on: {
                                click: function ($event) {
                                  field.sortBy
                                    ? _vm.sortTable(field.sortBy)
                                    : null
                                },
                              },
                            },
                            [
                              _vm._v(" " + _vm._s(field.label) + " "),
                              field.sortBy == _vm.orderBy
                                ? _c("span", { key: _vm.sortDir }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.sortDir == "DESC" ? "▼" : "▲"
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                  _vm.tableData.length
                    ? _c(
                        "tbody",
                        _vm._l(_vm.tableData, function (item, i) {
                          return _c("tr", { key: item.id + "_" + i }, [
                            _c(
                              "td",
                              [
                                _c("CInputCheckbox", {
                                  key: i,
                                  ref: "checkboxTable",
                                  refInFor: true,
                                  attrs: {
                                    custom: "",
                                    color: "primary-contrast",
                                    checked: _vm.selectedStudentIds.includes(
                                      item.id
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleCheckbox(
                                        item,
                                        $event.srcElement.checked
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatDate")(
                                    item.created_at,
                                    "DD.MM.YYYY HH:mm"
                                  )
                                )
                              ),
                            ]),
                            _c("td", [_vm._v(_vm._s(item.user.name))]),
                            _c("td", [_vm._v(_vm._s(item.user.email))]),
                            _c("td", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    target: "_blank",
                                    href: `https://t.me/${item.user.tg_nick}`,
                                  },
                                },
                                [_vm._v(_vm._s(item.user.tg_nick))]
                              ),
                            ]),
                            _c(
                              "td",
                              [
                                item.enter_tg_channel
                                  ? _c("CIcon", { attrs: { name: "check" } })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("td", [
                              _c(
                                "span",
                                {
                                  staticClass: "table-link table-link-hover",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editTariff(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.tariff?.name[
                                          _vm.$store.state.settings.global
                                            .locale
                                        ]
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "td",
                              [
                                item.finished_at
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "table-link table-link-hover",
                                          on: {
                                            click: function ($event) {
                                              return _vm.editFinishDate(item)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("formatDate")(
                                                  item.finished_at,
                                                  "DD.MM.YYYY"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : [_c("span", [_vm._v(" - ")])],
                              ],
                              2
                            ),
                            _c("td", [
                              _vm._v(
                                _vm._s(item?.education_flows?.[0]?.flow?.name)
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "studentTable__hasMentorshipIcon",
                                },
                                [
                                  item.has_mentor
                                    ? _c("CIcon", { attrs: { name: "check" } })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "span",
                                {
                                  staticClass: "table-link table-link-hover",
                                  on: {
                                    click: function ($event) {
                                      return _vm.appointOneStudent(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.curator
                                          ? item.curator.name ||
                                              item.curator.user.email
                                          : _vm.$t("lk.studentTable.appoint")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
                _vm.isLoading ? _c("CElementCover") : _vm._e(),
                !_vm.tableData.length && !_vm.isLoading
                  ? _c("span", { staticClass: "studentTable__placeholder" }, [
                      _vm._v(" " + _vm._s(_vm.$t("general.emptySearch")) + " "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }