var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "trainingFlowsTableItem w-100" },
    [
      _vm.showAppointCuratorModal
        ? _c("AppointCuratorInFlow", {
            attrs: {
              show: _vm.showAppointCuratorModal,
              flow: _vm.flowData,
              selectedCuratorId: _vm.selectedCuratorId,
              selectedStudentIds: _vm.selectedStudents,
            },
            on: {
              close: function ($event) {
                _vm.showAppointCuratorModal = false
              },
              success: function ($event) {
                _vm.resetSelection()
                _vm.$emit("updateFlows")
              },
              "update:show": function ($event) {
                _vm.showAppointCuratorModal = $event
              },
            },
          })
        : _vm._e(),
      _vm.showAddStudentModal
        ? _c("AddStudentModal", {
            ref: "addStudentModal",
            attrs: { currentFlow: _vm.flowData, show: _vm.showAddStudentModal },
            on: {
              close: function ($event) {
                _vm.showAddStudentModal = false
              },
              updateFlows: function ($event) {
                _vm.resetSelection()
                _vm.$emit("updateFlows")
              },
              "update:show": function ($event) {
                _vm.showAddStudentModal = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "trainingFlowsTableItem__header" },
        [
          _c(
            "span",
            { staticClass: "mb-0 mr-3", attrs: { color: "base-card-bg" } },
            [
              _c(
                "CButton",
                {
                  staticClass: "mr-1",
                  attrs: { color: "success" },
                  on: { click: _vm.toggleShowAddFlowModal },
                },
                [_c("CIcon", { attrs: { name: "cil-pencil" } })],
                1
              ),
              _c(
                "span",
                { staticClass: "trainingFlowsTableItem__header__white mr-3" },
                [_vm._v(" " + _vm._s(_vm.flowData.name) + " ")]
              ),
              _c(
                "span",
                { staticClass: "trainingFlowsTableItem__header__white" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$tc(
                          "lk.studentTable.selected",
                          _vm.selectedStudents.length
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _vm._v(
                " " +
                  _vm._s(_vm.selectedStudents.length) +
                  " " +
                  _vm._s(
                    _vm.$tc(
                      "lk.studentTable.students",
                      _vm.selectedStudents.length
                    )
                  ) +
                  " "
              ),
            ],
            1
          ),
          _c(
            "span",
            { staticClass: "trainingFlowsTableItem__header__white __data" },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("lk.training.flowTable.dates")) +
                  ": " +
                  _vm._s(
                    _vm._f("formatDate")(_vm.flowData.start_at, "DD.MM.YY")
                  ) +
                  " - " +
                  _vm._s(
                    _vm._f("formatDate")(_vm.flowData.finish_at, "DD.MM.YY")
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "CLoadingButtonCustom",
            {
              staticClass: "trainingFlowsTableItem__header-btn",
              attrs: { color: "dark" },
              nativeOn: {
                click: function ($event) {
                  return _vm.toggleShowAddStudentsModal.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lk.training.flowTable.addStudents")) + " ")]
          ),
          _c(
            "CLoadingButtonCustom",
            {
              staticClass: "trainingFlowsTableItem__header-btn",
              attrs: {
                color: "primary",
                disabled: !_vm.selectedStudents.length,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.appointManyStudents.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lk.studentTable.appointCurators")) + " ")]
          ),
        ],
        1
      ),
      _vm.flowData.flowUsers && _vm.flowData.flowUsers.length
        ? _c(
            "CCard",
            {
              staticClass: "shadow-none trainingFlowsTableItem__card",
              attrs: { color: "second-card-bg" },
            },
            [
              _c("CCardBody", { staticClass: "h-100 d-flex flex-column" }, [
                _c("div", { staticClass: "table-wrap" }, [
                  _c(
                    "table",
                    { staticClass: "table mb-0 trainingFlowsTableItem__table" },
                    [
                      _c("thead", [
                        _c(
                          "tr",
                          [
                            _c(
                              "th",
                              { staticStyle: { width: "4%" } },
                              [
                                _c("CInputCheckbox", {
                                  ref: "checkboxAll",
                                  attrs: {
                                    custom: "",
                                    color: "primary-contrast",
                                    checked: _vm.isAllStudentsChecked,
                                  },
                                  on: { change: _vm.selectAll },
                                }),
                              ],
                              1
                            ),
                            _vm._l(_vm.fields, function (field, i) {
                              return _c(
                                "th",
                                {
                                  key: i,
                                  class: field._classes,
                                  on: {
                                    click: function ($event) {
                                      field.sortBy
                                        ? _vm.sortTable(field.sortBy)
                                        : null
                                    },
                                  },
                                },
                                [
                                  _vm._v(" " + _vm._s(field.label) + " "),
                                  field.sortBy == _vm.orderBy
                                    ? _c("span", { key: _vm.sortDir }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.sortDir == "DESC" ? "▼" : "▲"
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.flowData.flowUsers, function (item, i) {
                          return _c("tr", { key: item.id + "_" + i }, [
                            _c(
                              "td",
                              [
                                _c("CInputCheckbox", {
                                  key: i,
                                  ref: "checkboxTable",
                                  refInFor: true,
                                  attrs: {
                                    custom: "",
                                    color: "primary-contrast",
                                    checked: _vm.selectedStudents.includes(
                                      item.user_id
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleCheckbox(
                                        item,
                                        $event.srcElement.checked
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatDate")(
                                    item.event_user.created_at,
                                    "DD.MM.YYYY HH:mm"
                                  )
                                )
                              ),
                            ]),
                            _c("td", [_vm._v(_vm._s(item.user.name))]),
                            _c("td", [_vm._v(_vm._s(item.user.email))]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  item.event_user.tariff?.name[
                                    _vm.$store.state.settings.global.locale
                                  ]
                                )
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "span",
                                {
                                  staticClass: "table-link table-link-hover",
                                  on: {
                                    click: function ($event) {
                                      return _vm.appointOneStudent(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.curator
                                          ? item.curator.name ||
                                              item.curator.user.email
                                          : _vm.$t("lk.studentTable.appoint")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "td",
                              { staticClass: "text-right text-nowrap" },
                              [
                                _c(
                                  "CLoadingButtonCustom",
                                  {
                                    attrs: {
                                      color: "danger",
                                      loading: _vm.checkLoadingId(item),
                                      disabled: _vm.checkLoadingId(item),
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.deleteItem(item, $event)
                                      },
                                    },
                                  },
                                  [
                                    _c("CIcon", {
                                      attrs: { name: "cilDelete" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        }),
                        0
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }