<template>
   <div class="trainingFlowsTableItem w-100">
    <AppointCuratorInFlow
      @close="showAppointCuratorModal = false"
      @success="resetSelection();$emit('updateFlows')"
      v-if="showAppointCuratorModal"
      :show.sync="showAppointCuratorModal"
      :flow="flowData"
      :selectedCuratorId="selectedCuratorId"
      :selectedStudentIds="selectedStudents"
    />
    <AddStudentModal
      ref="addStudentModal"
      v-if="showAddStudentModal"
      @close="showAddStudentModal = false"
      @updateFlows="resetSelection();$emit('updateFlows')"
      :currentFlow="flowData"
      :show.sync="showAddStudentModal"
    />
      <div class="trainingFlowsTableItem__header">
        <span class="mb-0 mr-3" color="base-card-bg">
          <CButton
          class="mr-1"
          color="success"
          @click="toggleShowAddFlowModal"
          >
            <CIcon name="cil-pencil" />
          </CButton>
          <span class="trainingFlowsTableItem__header__white mr-3">
            {{ flowData.name }}
          </span>
          <span class="trainingFlowsTableItem__header__white">
            {{ $tc('lk.studentTable.selected', selectedStudents.length) }}
          </span>
          {{ selectedStudents.length }}
          {{ $tc('lk.studentTable.students', selectedStudents.length) }}
        </span>

          <span class="trainingFlowsTableItem__header__white __data">
            {{ $t('lk.training.flowTable.dates') }}:
            {{ flowData.start_at | formatDate('DD.MM.YY') }}
            -
            {{ flowData.finish_at | formatDate('DD.MM.YY') }}
          </span>
          <CLoadingButtonCustom
            class="trainingFlowsTableItem__header-btn"
            color="dark"
            @click.native="toggleShowAddStudentsModal"
            >{{ $t('lk.training.flowTable.addStudents') }}
          </CLoadingButtonCustom>
          <CLoadingButtonCustom
            class="trainingFlowsTableItem__header-btn"
            color="primary"
            :disabled="!selectedStudents.length"
            @click.native="appointManyStudents"
            >{{ $t('lk.studentTable.appointCurators') }}
          </CLoadingButtonCustom>

      </div>
      <CCard
      v-if="flowData.flowUsers && flowData.flowUsers.length"
      color="second-card-bg"
      class="shadow-none trainingFlowsTableItem__card">
        <CCardBody class="h-100 d-flex flex-column">
          <div class="table-wrap">
            <table class="table mb-0 trainingFlowsTableItem__table">
              <thead>
                <tr>
                  <th style="width:4%">
                    <CInputCheckbox
                      custom
                      ref="checkboxAll"
                      color="primary-contrast"
                      :checked="isAllStudentsChecked"
                      @change="selectAll"
                    />
                  </th>
                  <th
                  v-for="(field, i) in fields"
                  :key="i"
                  @click="field.sortBy ? sortTable(field.sortBy) : null"
                  :class="field._classes">
                    {{ field.label }}
                    <span
                    v-if="field.sortBy == orderBy"
                    :key="sortDir">
                    {{sortDir == 'DESC' ? '▼' : '▲'}}
                  </span>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, i) in flowData.flowUsers" :key="item.id + '_' + i">
                  <td>
                    <CInputCheckbox
                      custom
                      :key="i"
                      color="primary-contrast"
                      ref="checkboxTable"
                      @change="handleCheckbox(item, $event.srcElement.checked)"
                      :checked="selectedStudents.includes(item.user_id)"
                    />
                  </td>
                  <td>{{ item.event_user.created_at | formatDate('DD.MM.YYYY HH:mm') }}</td>
                  <td>{{ item.user.name }}</td>
                  <td>{{ item.user.email }}</td>
                  <td>{{ item.event_user.tariff?.name[$store.state.settings.global.locale] }}</td>
                  <td>
                    <span
                    @click="appointOneStudent(item)"
                    class="table-link table-link-hover">
                      {{
                        item.curator
                          ? item.curator.name || item.curator.user.email
                          : $t('lk.studentTable.appoint')
                      }}
                    </span>
                  </td>
                  <td class="text-right text-nowrap">
                    <CLoadingButtonCustom
                      color="danger"
                      :loading="checkLoadingId(item)"
                      :disabled="checkLoadingId(item)"
                      @click.stop.native="deleteItem(item, $event)"
                    >
                      <CIcon name="cilDelete"></CIcon>
                    </CLoadingButtonCustom>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CCardBody>
      </CCard>
   </div>
</template>

<script>
import AddStudentModal from './AddStudentModal'
import AppointCuratorInFlow from './AppointCuratorInFlow'
import axios from 'axios'

export default {
  name: 'TrainingFlowsTableItem',
  components: { AddStudentModal, AppointCuratorInFlow },
  props: {
    flowData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      selectedCuratorId: null,
      loadingDel: [],
      orderBy: 'tariff',
      sortDir: "DSEC",
      showAddStudentModal: false,
      showAppointCuratorModal: false,
      selectedStudents: [],
      fields: [
        {
          key: 'date',
          _classes: 'align-middle tableHover',
          label: this.$t('lk.training.studentTable.dateBuy'),
        },
        {
          key: 'user_name',
          _classes: 'align-middle tableHover',
          label: this.$t('lk.training.studentTable.name'),
        },
        {
          key: 'user_email',
          _classes: 'align-middle tableHover',
          label: this.$t('lk.training.studentTable.email'),
        },
        {
          key: 'tariff',
          _classes: 'align-middle tableHover',
          label: this.$t('lk.training.studentTable.tariff'),
          sortBy: 'tariff'
        },
        {
          key: 'curator',
          _classes: 'align-middle',
          label: this.$t('lk.training.studentTable.curator'),
        },
        {
          _classes: 'align-middle',
          label: '',
        },
      ],
    }
  },
  computed: {
    isAllStudentsChecked(){
      return this.flowData.flowUsers.every(item => this.selectedStudents.includes(item.user_id))
    }
  },
  methods: {
    appointOneStudent(student) {
      this.resetSelection()
      this.selectedStudents.push(student.user_id)
      this.selectedCuratorId = student.curator?.id
      this.showAppointCuratorModal = true
    },
    appointManyStudents() {
      if (this.selectedStudents.length) {
        this.selectedCuratorId = null
        this.showAppointCuratorModal = true
      }
    },
    checkLoadingId(item) {
      return this.loadingDel.some(id => id === item.id)
    },
    async deleteItem (student, e) {
      this.loadingDel.push(student.user.id)
      try {
        e.target.blur()
        await axios.delete(`/api/v2/education-flows/${this.flowData.id}/drop-users`, {
          data:{
            user_ids: [student.user.id]
          }
        })
        this.$toastr.success(this.$t("general.dataSaved"));
        this.$emit('updateFlows')
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingDel = this.loadingDel.filter(id => id !== student.user.id)
      }
    },
    sortTable(sortBy) {
      if(sortBy != this.orderBy) {
        this.orderBy = sortBy
        this.sortDir = "DESC"
      } else {
        this.sortDir == "DESC" ? this.sortDir = "ASC" : this.sortDir = "DESC"
      }
    },
    selectAll(e) {
      if (e.srcElement.checked) {
        this.flowData.flowUsers.forEach((student) => {
          if (!this.selectedStudents.includes(student.user_id)) {
            this.selectedStudents.push(student.user_id)
          }
        })
      } else {
        this.resetSelection()
      }
    },
    handleCheckbox(student, checked) {
      if (checked && !this.selectedStudents.includes(student.user_id)) {
        this.selectedStudents.push(student.user_id)
      } else {
        const index = this.selectedStudents.indexOf(student.user_id)
        if (index !== -1) {
          this.selectedStudents.splice(index, 1)
        }
      }
    },
    resetSelection() {
      this.selectedStudents = []
    },
    async toggleShowAddStudentsModal(){
      this.showAddStudentModal = true
    },
    toggleShowAddFlowModal(){
      this.$emit('editFlow')
    },
  },
}
</script>

<style lang="scss" scoped>
.ccard-body {
  overflow: hidden;
}

.table {
  position: relative;

  thead tr th {
    background: var(--second-card-bg);
    position: sticky;
    top: -1px;
    z-index: 1;
  }
}

.trainingFlowsTableItem {
  width: 100%;

  .table-wrap {
    overflow: auto;
    width: 100%;
    flex-grow: 1;
    height: 300px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid var(--subscribe-card-bg);
    color: #20a8d8;
    padding: 12px 24px;
    align-items: flex-start;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: center;
    }

    &__white {
      color: var(--white);
      &.__data{
        @include media-breakpoint-up(lg) {
          margin-left: auto;
        }
      }
    }

    &-btn {
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: auto;
      }
    }
  }

  &__card {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-bottom: 0;
  }

  &__table {
    tbody {
      max-height: 225px;
    }

    tbody tr td {
      &:nth-child(1) {
        width: 10px;
      }

      &:nth-child(2) {
        width: 14%;
      }

      &:nth-child(3) {
        width: 18%;
      }

      &:nth-child(4) {
        width: 20.9%;
      }

      &:nth-child(5) {
        width: 15%;
      }

      &:nth-child(6) {
        width: 8%;
      }

      &:nth-child(7) {
        width: 15%;
      }

      &:nth-child(8) {
        width: 9.2%;
      }
    }
  }

  .modal-header {
    border-bottom: none;
  }
}

.table-link {
  color: var(--white);
}

.table-link-hover {
  color: var(--primary);
  cursor: pointer;

  &:hover {
    color: var(--white);
    text-decoration: underline;
  }
}

.tableHover:hover {
    cursor: pointer;
    color: var(--white);
    text-decoration: underline;
  }
</style>
