var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CModal", {
    staticClass: "addStudentUserModal",
    attrs: { centered: "", show: _vm.show },
    on: {
      "update:show": function ($event) {
        return _vm.$emit("update:show", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "header-wrapper",
        fn: function () {
          return [
            _c("div", { staticClass: "addStudentUserModal__header" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("lk.training.addStudentUser.modalTitle"))),
              ]),
              _c(
                "div",
                {
                  staticClass: "addStudentUserModal__header-close",
                  on: { click: _vm.close },
                },
                [_vm._v("×")]
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "body-wrapper",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "addStudentUserModal__body" },
              [
                _c("v-select-input", {
                  attrs: {
                    options: _vm.userList,
                    clearable: false,
                    "get-option-label": (option) =>
                      option.id + ". " + option.name + "(" + option.email + ")",
                    placeholder: _vm.$t(
                      "lk.training.addStudentUser.searchPlaceholder"
                    ),
                  },
                  on: { search: _vm.onSearch },
                  scopedSlots: _vm._u([
                    {
                      key: "prepend",
                      fn: function () {
                        return [_c("CIcon", { attrs: { name: "mail" } })]
                      },
                      proxy: true,
                    },
                    {
                      key: "no-options",
                      fn: function () {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm.$t("general.emptySearch")) + " "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.selectedUser,
                    callback: function ($$v) {
                      _vm.selectedUser = $$v
                    },
                    expression: "selectedUser",
                  },
                }),
                _c("CSelect", {
                  attrs: {
                    value: _vm.selectedTariff,
                    label: `${_vm.$t("lk.permission.subscription")}:`,
                    placeholder: _vm.$t("lk.permission.chooseAPlan"),
                    options: _vm.tariffListForUser,
                  },
                  on: {
                    "update:value": function ($event) {
                      _vm.selectedTariff = $event
                    },
                  },
                }),
                _c("label", [
                  _vm._v(
                    _vm._s(_vm.$t("lk.training.addStudentUser.finishedAt"))
                  ),
                ]),
                _c("v-date-picker", {
                  attrs: {
                    mode: "dateTime",
                    "is-dark": _vm.$store.getters.darkMode,
                    is24hr: "",
                    masks: { input: "DD.MM.YYYY HH:mm" },
                    locale: _vm.$root.$i18n.locale,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ inputValue, inputEvents }) {
                        return [
                          _c(
                            "CInput",
                            _vm._g(
                              {
                                staticClass: "input",
                                attrs: {
                                  value: inputValue,
                                  placeholder: _vm.$t(
                                    "lk.training.addStudentUser.datePlaceholder"
                                  ),
                                  readonly: "",
                                },
                              },
                              inputEvents
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.selectedFinishedAt,
                    callback: function ($$v) {
                      _vm.selectedFinishedAt = $$v
                    },
                    expression: "selectedFinishedAt",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "addStudentUserModal__btnBlock" },
                  [
                    _c(
                      "CButton",
                      {
                        staticClass: "shadow-none addStudentUserModal__btn",
                        attrs: {
                          disabled: !_vm.isValidate || _vm.isLoading,
                          color: "success",
                        },
                        on: { click: _vm.saveTariff },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("general.save")) + " ")]
                    ),
                    _c(
                      "CButton",
                      {
                        staticClass: "shadow-none addStudentUserModal__btn",
                        attrs: { color: "primary" },
                        on: { click: _vm.close },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("general.cancel")) + " ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.isLoading ? _c("CElementCover") : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "footer-wrapper",
        fn: function () {
          return [_c("span")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }