var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      staticClass: "add-student-modal",
      attrs: {
        centered: "",
        show: _vm.show,
        closeOnBackdrop: false,
        title: _vm.$t("lk.training.flowTable.addStudents"),
      },
      on: {
        "update:show": function ($event) {
          return _vm.$emit("update:show", $event)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer-wrapper",
          fn: function () {
            return [_c("span")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "CForm",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.saveStudents.apply(null, arguments)
            },
          },
        },
        [
          _c("label", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("lk.training.flowTable.studentsListLabel")) +
                ": "
            ),
          ]),
          _c("v-select-input", {
            staticClass: "mb-0 students-select",
            attrs: {
              placeholder: _vm.$t("lk.curator.student"),
              options: _vm.studentsList,
              multiple: "",
            },
            scopedSlots: _vm._u([
              {
                key: "no-options",
                fn: function () {
                  return [
                    _vm._v(" " + _vm._s(_vm.$t("general.emptySearch")) + " "),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.selectedStudents,
              callback: function ($$v) {
                _vm.selectedStudents = $$v
              },
              expression: "selectedStudents",
            },
          }),
          _c(
            "div",
            { staticClass: "add-flow-modal__button-wrapper d-flex w-100 mt-4" },
            [
              _c(
                "CLoadingButtonCustom",
                {
                  staticClass: "mr-3 flex-grow-1",
                  attrs: {
                    color: "success",
                    loading: _vm.loading,
                    disabled: _vm.loading,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.saveStudents.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("general.save")) + " ")]
              ),
              _c(
                "CButton",
                {
                  staticClass: "flex-grow-1",
                  attrs: { color: "dark" },
                  on: { click: _vm.close },
                },
                [_vm._v(" " + _vm._s(_vm.$t("general.cancel")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }