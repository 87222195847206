<template>
  <div class="page-training">
    <time-range-selector
      :hideTitle="true"
      :options="btnOptions"
      v-model="selectedTab"
      class="tabSelector"
    />
    <div class="header d-flex">
      <h1 class="title mb-0 mr-3">
        {{ $t(`lk.training.headers[${selectedTab}]`) }}
      </h1>
      <CLoadingButtonCustom
        v-if="selectedTab === 3"
        color="primary"
        @click.native="$refs['materials'].loadMaterials()"
      >
        <CIcon name="cilReload"/>
      </CLoadingButtonCustom>
      <CButton
        v-if="selectedTab === 4"
        color="primary"
        @click="toggleAddFlowModal"
      >
        {{ $t('lk.training.flowTable.addFlow') }}
      </CButton>
      <!-- <CSpinner color="primary" size="sm" /> -->
    </div>
    <div class="page-training__view-wrapper">
      <TrainingPreview v-if="selectedTab === 0" :eventId="getRouteId" />
      <TrainingStudentTable v-if="selectedTab === 1" :eventId="getRouteId" />
      <TrainingCuratorTable v-if="selectedTab === 2" :eventId="getRouteId" />
      <TrainingMaterials ref="materials" v-if="selectedTab === 3" :eventId="getRouteId" />
      <TrainingFlowsTables ref="flows" v-if="selectedTab === 4" :eventId="getRouteId" />
      <TrainingSettings v-if="selectedTab === 5" :eventId="getRouteId" />
    </div>
  </div>
</template>

<script>
import TrainingPreview from "@/components/training/TrainingPreview"
import TrainingStudentTable from "@/components/training/TrainingStudentTable"
import TrainingCuratorTable from "@/components/training/TrainingCuratorTable"
import TrainingFlowsTables from "@/components/training/TrainingFlowsTables"
import TimeRangeSelector from '@/components/training/tabSelector'
import TrainingMaterials from "@/components/training/TrainingMaterials"
import TrainingSettings from "@/components/training/TrainingSettings"

export default {
  name: "PageTraining",
  components: {
    TrainingPreview,
    TrainingMaterials,
    TrainingStudentTable,
    TrainingCuratorTable,
    TimeRangeSelector,
    TrainingFlowsTables,
    TrainingSettings,
  },
  data() {
    return {
      selectedTab: +(this.$route.params.tab || 3),
      loadingTariffs: false,
      btnOptions: [
        {
          label: this.$t(`lk.training.materials`),
          key: 3,
          iconName: 'cilCopy'
        },
        {
          label: this.$t(`lk.training.preview`),
          key: 0,
          iconName: 'cil-image'
        },
        {
          label: this.$t(`lk.training.students`),
          key: 1,
          iconName: 'cil-user'
        },
        {
          label: this.$t(`lk.training.curators`),
          key: 2,
          iconName: 'cilEducation'
        },
        {
          label: this.$t(`lk.training.flows`),
          key: 4,
          iconName: 'cil-user'
        },
        {
          label: this.$t(`lk.training.settings`),
          key: 5,
          iconName: 'cil-settings'
        },
      ]
    };
  },
  computed: {
    getRouteId() {
      return parseInt(this.$route.params.id);
    },
  },
  mounted() {
    /* this.$root.$on("tabGroupe-changeTab", (id) => {
      this.selectedTab = id;
    }); */
  },
  methods:{
    async toggleAddFlowModal(){
    await this.$refs.flows.toggleShowAddFlowModal()
   }
  }
};
</script>

<style lang="scss" scoped>
.page-training{
  display: flex;
  flex-direction: column;
  height: 100%;
  &__view-wrapper{
    display: flex;
    height: 80%;
  }
}
.header {
  margin-bottom: 20px;
}

.tabSelector {
  flex-shrink: 0;
  margin-bottom: 16px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 24px;
  }
}
</style>
