<template>
  <CModal
    centered
    :show="show"
    @update:show="$emit('update:show', $event)"
    class="deleteStudentModal"
  >
    <template #header-wrapper>
      <div class="deleteStudentModal__header">
        <span>{{ $t('lk.training.deleteStudentModal.title') }}</span>
        <div @click="close" class="deleteStudentModal__header-close">×</div>
      </div>
    </template>

    <template #body-wrapper>
      <div class="deleteStudentModal__body">
        <p>
          {{
            $tc('lk.training.deleteStudentModal.text', selectedStudentIdsLength, {
              count: selectedStudentIdsLength,
            })
          }}
        </p>
        <div class="deleteStudentModal__btnBlock">
          <CButton
            @click="deleteStudents"
            class="shadow-none deleteStudentModal__btn"
            color="danger"
          >
            {{ $t('general.delete') }}
          </CButton>
          <CButton @click="close" class="shadow-none deleteStudentModal__btn" color="primary">
            {{ $t('general.cancel') }}
          </CButton>
        </div>
      </div>
      <CElementCover v-if="isLoading" />
    </template>

    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>
import axios from 'axios'

export default {
  name: 'DeleteStudentModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    eventId: {
      type: Number,
      required: true,
    },
    selectedStudentIds: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
    }
  },

  computed: {
    selectedStudentIdsLength() {
      return this.selectedStudentIds.length
    },
  },

  methods: {
    deleteStudents() {
      if (!this.isLoading && this.selectedStudentIdsLength) {
        this.isLoading = true
        let params = { id: this.selectedStudentIds }
        axios
          .delete(`/api/v2/events/${this.eventId}/users`, { data: params })
          .then(() => {
            this.$toastr.success(this.$t('lk.training.deleteStudentModal.deleteComplete'))
            this.$emit('success')
            this.close()
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },

    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.deleteStudentModal {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 0;
    font-size: 21px;
    line-height: 1;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      padding: 24px 32px 0;
    }

    &-close {
      font-size: 23px;
      font-weight: 100;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $danger;
      }
    }
  }

  &__btn {
    width: 100%;
    height: 44px;
  }

  &__btnBlock {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: 20px;
    }
  }

  &__body {
    @include media-breakpoint-up(md) {
      padding: 24px 32px 32px;
    }
  }
}
</style>
