<template>
  <CModal centered :show="show" @update:show="$emit('update:show', $event)" class="curatorModal">
    <template #header-wrapper
      ><div class="curatorModal__header">
        <span>{{ renderTitle() }}</span>
        <div @click="close" class="curatorModal__header-close">×</div>
      </div></template
    >

    <template #body-wrapper>
      <div class="curatorModal__body">
        <v-select-input
          v-model="user"
          :options="userList"
          :clearable="false"
          :get-option-label="(option) => option.email"
          :placeholder="$t('lk.training.curatorModal.emailPlaceholder')"
          @search="onSearch"
        >
          <template #prepend>
            <CIcon name="mail" />
          </template>
          <template #no-options>
            {{ $t('general.emptySearch') }}
          </template>
        </v-select-input>
        <div class="curatorModal__btnBlock">
          <CButton
            @click="saveCurator"
            class="shadow-none curatorModal__btn"
            :disabled="!user"
            color="success"
          >
            {{ renderBtnText() }}
          </CButton>
          <CButton class="shadow-none curatorModal__btn" color="primary" @click="close">
            {{ $t('general.cancel') }}
          </CButton>
        </div>
      </div>
      <CElementCover v-if="isLoading" />
    </template>

    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>
import axios from 'axios'
import debounce from 'lodash/debounce'
import VSelectInput from '@/components/VSelectInput'

export default {
  name: 'CuratorModal',
  components: {
    VSelectInput,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    curator: {
      type: Object,
      required: true,
    },
    searchDebounce: {
      type: Number,
      default: 300,
    },
    eventId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      userList: [],
      user: null,
    }
  },
  beforeUpdate() {
    if (this.curator.id) {
      this.user = { ...this.curator.user }
      this.userList = [{ ...this.curator.user }]
    } else {
      this.user = null
      this.userList = []
    }
  },
  methods: {
    saveCurator() {
      if (!this.isLoading && this.user) {
        this.isLoading = true
        let url = '/api/v1/curators'
        let method = 'post'
        const data = { user_id: this.user.id }
        if (this.curator.id) {
          url += `/${this.curator.id}`
          method = 'put'
        }else{
          data['event_id'] = this.eventId
        }
        axios({
          method: method,
          url: url,
          data: data,
        })
          .then((resp) => {
            const message = this.curator.id
              ? this.$t('lk.training.curatorModal.completeUpdate')
              : this.$t('lk.training.curatorModal.completeAdd')
            this.$toastr.success(message)
            this.$emit('success')
            this.close()
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },

    onSearch(search, loading) {
      if (search.length && search.length >= 3) {
        loading(true)
        this.search(loading, search, this)
      }
    },

    search: debounce((loading, search, vm) => {
      axios
        .get('api/v1/users/autocomplete', { params: { term: search } })
        .then((res) => {
          vm.userList = res.data.data
        })
        .finally(() => {
          loading(false)
        })
    }, 300),

    renderTitle() {
      return this.curator.id
        ? this.$t('lk.training.curatorModal.titleUpdate')
        : this.$t('lk.training.curatorModal.titleAdd')
    },

    renderBtnText() {
      return this.curator.id
        ? this.$t('lk.training.curatorModal.btnUpdate')
        : this.$t('lk.training.curatorModal.btnAdd')
    },

    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.curatorModal {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 0;
    font-size: 21px;
    line-height: 1;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      padding: 24px 32px 0;
    }

    &-close {
      font-size: 23px;
      font-weight: 100;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $danger;
      }
    }
  }

  &__btn {
    width: 100%;
    height: 44px;
  }

  &__btnBlock {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: 20px;
    }
  }

  &__body {
    padding: 20px 16px 20px;

    @include media-breakpoint-up(md) {
      padding: 24px 32px 32px;
    }
  }
}
</style>
