var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CModal", {
    staticClass: "deleteStudentModal",
    attrs: { centered: "", show: _vm.show },
    on: {
      "update:show": function ($event) {
        return _vm.$emit("update:show", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "header-wrapper",
        fn: function () {
          return [
            _c("div", { staticClass: "deleteStudentModal__header" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("lk.training.deleteStudentModal.title"))),
              ]),
              _c(
                "div",
                {
                  staticClass: "deleteStudentModal__header-close",
                  on: { click: _vm.close },
                },
                [_vm._v("×")]
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "body-wrapper",
        fn: function () {
          return [
            _c("div", { staticClass: "deleteStudentModal__body" }, [
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$tc(
                        "lk.training.deleteStudentModal.text",
                        _vm.selectedStudentIdsLength,
                        { count: _vm.selectedStudentIdsLength }
                      )
                    ) +
                    " "
                ),
              ]),
              _c(
                "div",
                { staticClass: "deleteStudentModal__btnBlock" },
                [
                  _c(
                    "CButton",
                    {
                      staticClass: "shadow-none deleteStudentModal__btn",
                      attrs: { color: "danger" },
                      on: { click: _vm.deleteStudents },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("general.delete")) + " ")]
                  ),
                  _c(
                    "CButton",
                    {
                      staticClass: "shadow-none deleteStudentModal__btn",
                      attrs: { color: "primary" },
                      on: { click: _vm.close },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("general.cancel")) + " ")]
                  ),
                ],
                1
              ),
            ]),
            _vm.isLoading ? _c("CElementCover") : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "footer-wrapper",
        fn: function () {
          return [_c("span")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }