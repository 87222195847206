<template>
  <div class="timeCodeItem d-flex justify-content-between mb-3">
    <span @click="$emit('removeMarker')">
      <CIcon  width="0.7rem" name="cil-x" class="timeCodeItem__deleteIcon"/>
    </span>
    <div class="form-group timeCodeItem__time mb-0">
      <the-mask placeholder="hh:mm:ss" class="form-control" :masked="true" :mask="['##:##:##']" v-model="marker.time"/>
    </div>
    <CInput
     class="timeCodeItem__text mb-0"
     type="text"
     v-model="marker.text"
    />
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask'
import { secToHHMMSS } from '@/assets/js/timeCode'
export default {
  name: "TimeCodeItem",
  components:{
    TheMask,
  },
  props:{
    marker:{
      type: Object
    }
  },
  created(){
    this.marker.time = secToHHMMSS(this.marker.time);
  },
}
</script>

<style lang="scss" scoped>
.timeCodeItem{
  &:last-child{
    margin-bottom: 0 !important;
  }
  &__deleteIcon{
  cursor: pointer;
  color: var(--danger);
  &:hover{
    color: var(--sub-btn);
    }
  }
  &__time{
  width: 22%;
  }
  &__text{
    width: 70%;
  }
}
</style>
