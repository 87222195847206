var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _vm.loading
        ? _c("CSpinner", { attrs: { color: "primary", size: "lg" } })
        : _c(
            "div",
            { staticClass: "trainingMaterials" },
            [
              _c(
                "CCard",
                {
                  staticClass: "trainingMaterials__block",
                  attrs: { color: "second-card-bg" },
                },
                [
                  _c("CInput", {
                    staticClass: "pl-0",
                    attrs: {
                      label: _vm.$t("lk.training.nameTraining"),
                      type: "text",
                    },
                    model: {
                      value: _vm.course.course_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.course, "course_title", $$v)
                      },
                      expression: "course.course_title",
                    },
                  }),
                  _c("CInput", {
                    staticClass: "pl-0",
                    attrs: {
                      label: _vm.$t("lk.training.slugTraining"),
                      type: "text",
                    },
                    model: {
                      value: _vm.course.slug,
                      callback: function ($$v) {
                        _vm.$set(_vm.course, "slug", $$v)
                      },
                      expression: "course.slug",
                    },
                  }),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-lg-6" },
                      [
                        _c("label", [
                          _vm._v(
                            _vm._s(_vm.$t("lk.training.availableToBuyFrom"))
                          ),
                        ]),
                        _c("v-date-picker", {
                          attrs: {
                            mode: "dateTime",
                            "is-dark": _vm.$store.getters.darkMode,
                            is24hr: "",
                            masks: { input: "DD.MM.YYYY HH:mm" },
                            locale: _vm.$root.$i18n.locale,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ inputValue, inputEvents }) {
                                return [
                                  _c(
                                    "CInput",
                                    _vm._g(
                                      {
                                        staticClass: "input",
                                        attrs: {
                                          value: inputValue,
                                          placeholder: _vm.$t(
                                            "trends.datePlaceholder"
                                          ),
                                          readonly: "",
                                        },
                                      },
                                      inputEvents
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.course.availableToBuyFrom,
                            callback: function ($$v) {
                              _vm.$set(_vm.course, "availableToBuyFrom", $$v)
                            },
                            expression: "course.availableToBuyFrom",
                          },
                        }),
                        _c("label", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("lk.training.availableForTrainingFrom")
                            )
                          ),
                        ]),
                        _c("v-date-picker", {
                          attrs: {
                            mode: "dateTime",
                            "is-dark": _vm.$store.getters.darkMode,
                            is24hr: "",
                            masks: { input: "DD.MM.YYYY HH:mm" },
                            locale: _vm.$root.$i18n.locale,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ inputValue, inputEvents }) {
                                return [
                                  _c(
                                    "CInput",
                                    _vm._g(
                                      {
                                        staticClass: "input",
                                        attrs: {
                                          value: inputValue,
                                          placeholder: _vm.$t(
                                            "trends.datePlaceholder"
                                          ),
                                          readonly: "",
                                        },
                                      },
                                      inputEvents
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.course.availableForTrainingFrom,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.course,
                                "availableForTrainingFrom",
                                $$v
                              )
                            },
                            expression: "course.availableForTrainingFrom",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-lg-6 mb-3" },
                      [
                        _c(
                          "CButtonGroup",
                          {
                            staticClass:
                              "form-group trainingMaterials__header__btns w-100",
                          },
                          [
                            _c(
                              "CButton",
                              {
                                staticClass: "trainingMaterials__header__btn",
                                attrs: {
                                  color: _vm.course.published
                                    ? "success"
                                    : "dark",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.course.published = true
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("lk.training.active")) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "CButton",
                              {
                                staticClass: "trainingMaterials__header__btn",
                                attrs: {
                                  color: !_vm.course.published
                                    ? "danger"
                                    : "dark",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.course.published = false
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("lk.training.notActive")) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("lk.training.accessFlows"))),
                            ]),
                            _c("v-select", {
                              ref: "vselect",
                              staticClass: "v-select-flows",
                              attrs: {
                                closeOnSelect: false,
                                loading: _vm.loading,
                                options: _vm.educationFlows,
                                filter: _vm.flowsCustomFilter,
                                getOptionKey: (option) => option?.id,
                                getOptionLabel: (option) =>
                                  _vm.getFlowsOptionName(option),
                                multiple: "",
                                placeholder:
                                  _vm.accessInitType === "all"
                                    ? _vm.$t("lk.training.accessFlowsAll")
                                    : _vm.$t("lk.training.accessFlowsFlows"),
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "option",
                                  fn: function (option) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "rounded-pill" },
                                        [
                                          _c("span", { staticClass: "badge" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getFlowsOptionName(option)
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.course.accessFlows,
                                callback: function ($$v) {
                                  _vm.$set(_vm.course, "accessFlows", $$v)
                                },
                                expression: "course.accessFlows",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-lg-5 col-xl-4" },
                      [
                        _c("CInputFile", {
                          attrs: {
                            label: _vm.$t("lk.training.coverPhoto"),
                            accept: ".jpg, .jpeg, .png",
                            type: "file",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onChangeInputFile(
                                "course_cover_photo",
                                $event
                              )
                            },
                          },
                        }),
                        _vm.course.course_cover_photo
                          ? _c(
                              "div",
                              { staticClass: "trainingMaterials__img mb-4" },
                              [
                                _c("img", {
                                  attrs: {
                                    src: `${_vm.baseUrl}${_vm.course.course_cover_photo}`,
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _c("CInputFile", {
                          attrs: {
                            label: _vm.$t("lk.training.widePhoto"),
                            accept: ".jpg, .jpeg, .png",
                            type: "file",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onChangeInputFile(
                                "course_wide_photo",
                                $event
                              )
                            },
                          },
                        }),
                        _vm.course.course_wide_photo
                          ? _c(
                              "div",
                              { staticClass: "trainingMaterials__img mb-4" },
                              [
                                _c("img", {
                                  attrs: {
                                    src: `${_vm.baseUrl}${_vm.course.course_wide_photo}`,
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-lg-7 col-xl-8" },
                      [
                        _c("h3", [
                          _vm._v(
                            _vm._s(_vm.$t("lk.training.usefulLinks.header"))
                          ),
                        ]),
                        _vm._l(
                          _vm.course.links,
                          function (linkItem, linkItemI) {
                            return _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-sm" },
                                [
                                  _c("CInput", {
                                    attrs: {
                                      label: _vm.$t(
                                        "lk.training.usefulLinks.inputTitle"
                                      ),
                                      type: "text",
                                    },
                                    model: {
                                      value: linkItem.title,
                                      callback: function ($$v) {
                                        _vm.$set(linkItem, "title", $$v)
                                      },
                                      expression: "linkItem.title",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-sm d-flex align-items-center",
                                },
                                [
                                  _c("CInput", {
                                    staticClass: "flex-grow-1",
                                    attrs: {
                                      label: _vm.$t(
                                        "lk.training.usefulLinks.inputLink"
                                      ),
                                      type: "text",
                                    },
                                    model: {
                                      value: linkItem.link,
                                      callback: function ($$v) {
                                        _vm.$set(linkItem, "link", $$v)
                                      },
                                      expression: "linkItem.link",
                                    },
                                  }),
                                  _c(
                                    "CButton",
                                    {
                                      staticClass:
                                        "btn-sm shadow-none inline ml-3",
                                      staticStyle: { "margin-top": "12px" },
                                      attrs: { color: "outline-danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.course.links.splice(
                                            linkItemI,
                                            1
                                          )
                                        },
                                      },
                                    },
                                    [_c("CIcon", { attrs: { name: "cil-x" } })],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          }
                        ),
                        _c(
                          "CButton",
                          {
                            attrs: { color: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.course.links.push({
                                  title: "",
                                  link: "",
                                })
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("lk.training.usefulLinks.add")) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      2
                    ),
                  ]),
                  _c("tinymce", {
                    staticClass: "mb-0 w-100",
                    attrs: { label: _vm.$t("lk.training.descTraining") },
                    model: {
                      value: _vm.course.course_description,
                      callback: function ($$v) {
                        _vm.$set(_vm.course, "course_description", $$v)
                      },
                      expression: "course.course_description",
                    },
                  }),
                  _c(
                    "CLoadingButtonCustom",
                    {
                      staticClass: "cbtn mt-4 trainingMaterials__btn",
                      attrs: {
                        color: "primary",
                        loading: _vm.loadBtn,
                        disabled: _vm.loadBtn,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.editPreviewData.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("lk.training.btnSaveCourse")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }