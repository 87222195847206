<template>
  <CModal
    centered
    :show="show"
    title="Confirm delete"
    @update:show="$emit('update:show', $event)"
    class="deleteCuratorModal"
  >
    <template #header-wrapper>
      <div class="deleteCuratorModal__header">
        <span>{{ $t('lk.training.deleteCuratorModal.title') }}</span>
        <div @click="close" class="deleteCuratorModal__header-close">×</div>
      </div>
    </template>

    <template #body-wrapper>
      <div class="deleteCuratorModal__body">
        <p>
          {{ $t('lk.training.deleteCuratorModal.confirm') }}
          {{ curator?.name || curator?.user?.email }}
        </p>
        <div class="deleteCuratorModal__btnBlock">
          <CButton
            @click="deleteCurator"
            class="shadow-none deleteCuratorModal__btn"
            color="danger"
          >
            {{ $t('general.delete') }}
          </CButton>
          <CButton @click="close" class="shadow-none deleteCuratorModal__btn" color="primary">
            {{ $t('general.cancel') }}
          </CButton>
        </div>
      </div>
      <CElementCover v-if="isLoading" />
    </template>

    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>
import axios from 'axios'

export default {
  name: 'DeleteCuratorModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    curator: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    deleteCurator() {
      if (!this.isLoading && this.curator?.id) {
        this.isLoading = true
        axios
          .delete(`/api/v1/curators/${this.curator.id}`)
          .then((resp) => {
            this.$toastr.success(this.$t('lk.training.deleteCuratorModal.complete'))
            this.$emit('success')
            this.close()
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.deleteCuratorModal {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 0;
    font-size: 21px;
    line-height: 1;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      padding: 24px 32px 0;
    }

    &-close {
      font-size: 23px;
      font-weight: 100;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $danger;
      }
    }
  }

  &__btn {
    width: 100%;
    height: 44px;
  }

  &__btnBlock {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: 20px;
    }
  }

  &__body {
    @include media-breakpoint-up(md) {
      padding: 24px 32px 32px;
    }
  }
}
</style>
