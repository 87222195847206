var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CModal", {
    staticClass: "deleteCuratorModal",
    attrs: { centered: "", show: _vm.show, title: "Confirm delete" },
    on: {
      "update:show": function ($event) {
        return _vm.$emit("update:show", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "header-wrapper",
        fn: function () {
          return [
            _c("div", { staticClass: "deleteCuratorModal__header" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("lk.training.deleteCuratorModal.title"))),
              ]),
              _c(
                "div",
                {
                  staticClass: "deleteCuratorModal__header-close",
                  on: { click: _vm.close },
                },
                [_vm._v("×")]
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "body-wrapper",
        fn: function () {
          return [
            _c("div", { staticClass: "deleteCuratorModal__body" }, [
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("lk.training.deleteCuratorModal.confirm")) +
                    " " +
                    _vm._s(_vm.curator?.name || _vm.curator?.user?.email) +
                    " "
                ),
              ]),
              _c(
                "div",
                { staticClass: "deleteCuratorModal__btnBlock" },
                [
                  _c(
                    "CButton",
                    {
                      staticClass: "shadow-none deleteCuratorModal__btn",
                      attrs: { color: "danger" },
                      on: { click: _vm.deleteCurator },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("general.delete")) + " ")]
                  ),
                  _c(
                    "CButton",
                    {
                      staticClass: "shadow-none deleteCuratorModal__btn",
                      attrs: { color: "primary" },
                      on: { click: _vm.close },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("general.cancel")) + " ")]
                  ),
                ],
                1
              ),
            ]),
            _vm.isLoading ? _c("CElementCover") : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "footer-wrapper",
        fn: function () {
          return [_c("span")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }