var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.video.primary
    ? _c("div", { staticClass: "additionalMaterialCard pt-2" }, [
        _c(
          "div",
          {
            staticClass:
              "additionalMaterialCard__block justify-content-between",
          },
          [
            _c("div", { staticClass: "additionalMaterialCard__block-right" }, [
              _c(
                "div",
                {
                  staticClass:
                    "additionalMaterialCard__block-right-title d-flex align-items-center mb-2",
                },
                [
                  _c("span", { staticClass: "mr-3" }, [
                    _vm._v(
                      " Видео " +
                        _vm._s(_vm.primaryVideo ? _vm.index : _vm.index + 1) +
                        " "
                    ),
                  ]),
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$emit("removeVideoField", _vm.index)
                        },
                      },
                    },
                    [
                      _c("CIcon", {
                        staticClass:
                          "additionalMaterialCard__block-right-deleteIcon",
                        attrs: { width: "1.25rem", name: "cilDelete" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "additionalMaterialCard__block-right-selectVideo",
                },
                [
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center" },
                      [
                        _c(
                          "CLoadingButton",
                          {
                            staticClass: "mr-2",
                            attrs: { color: "success" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.$emit(
                                  "openVideoList",
                                  _vm.primaryVideo
                                    ? _vm.index + 1
                                    : _vm.index + 2
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    _vm.video.filename
                                      ? "lk.videoEncrypt.replaceVideo"
                                      : "lk.videoEncrypt.chooseVideo"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _vm.video.id
                          ? _c("div", [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("lk.videoEncrypt.selectedVideo")
                                    ) +
                                    ": "
                                ),
                              ]),
                              _c("span", [
                                _vm._v(" " + _vm._s(_vm.video.filename) + " "),
                              ]),
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("removeVideo", _vm.index)
                                    },
                                  },
                                },
                                [
                                  _c("CIcon", {
                                    staticClass:
                                      "additionalMaterialCard__block-right-deleteIcon",
                                    attrs: { width: "0.7rem", name: "cil-x" },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "additionalMaterialCard__block-left" },
              [
                _c("CInput", {
                  staticClass: "w-100 mb-0",
                  attrs: {
                    label: _vm.$t("lk.videoEncrypt.title"),
                    type: "text",
                  },
                  model: {
                    value: _vm.video.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.video, "name", $$v)
                    },
                    expression: "video.name",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "additionalMaterialCard__block pb-3" },
          [
            _c("tinymce", {
              staticClass: "mb-0 w-100",
              attrs: { label: _vm.$t("lk.videoEncrypt.table.description") },
              model: {
                value: _vm.video.description,
                callback: function ($$v) {
                  _vm.$set(_vm.video, "description", $$v)
                },
                expression: "video.description",
              },
            }),
          ],
          1
        ),
        _vm.video.filename
          ? _c(
              "div",
              {
                staticClass:
                  "additionalMaterialCard__video-time-code-wrapper mt-3",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "additionalMaterialCard__video-time-code-block d-flex align-items-center",
                  },
                  [
                    _c("CTextarea", {
                      staticClass: "mb-0 w-100",
                      attrs: {
                        label: _vm.$t("lk.videoEncrypt.timeCodes"),
                        rows: "5",
                      },
                      model: {
                        value: _vm.markers,
                        callback: function ($$v) {
                          _vm.markers = $$v
                        },
                        expression: "markers",
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }