<template>
  <div class="trainigFlowsTables w-100">
    <AddFlowModal
      ref="addFlowModal"
      @close="showAddFlowModal = false"
      @updateFlows="loadFlows"
      v-if="showAddFlowModal"
      :show.sync="showAddFlowModal"
      :currentFlow="currentFlow"
      :eventId="eventId"
    />
    <CSpinner v-if="loadingFlows" color="primary" size="lg" />
    <div v-else class="trainigFlowsTables__wrapper">
      <TrainingFlowsTableItem
      v-for="item in flowsList"
      @editFlow="toggleShowAddFlowModal(item)"
      :key="item.id"
      @updateFlows="loadFlows"
      :flowData="item"/>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import TrainingFlowsTableItem from './TrainingFlowsTableItem'
import AddFlowModal from './AddFlowModal'

export default {
  name: 'TrainingFlowsTables',
  components: { TrainingFlowsTableItem, AddFlowModal },
  props: {
    flowData: {
      type: Boolean,
      default: false
    },
    eventId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      currentFlow: null,
      loadingFlows: false,
      flowsList: [],
      showAddFlowModal: false,
    }
  },
  mounted() {
    this.loadFlows()
  },
  methods:{
    toggleShowAddFlowModal(flow){
      this.currentFlow = flow
      this.showAddFlowModal = true
    },
    async loadFlows() {
      try {
        this.loadingFlows = true
        this.flowsList = []
        const { data } = await axios.get(`/api/v2/education-flows`, {
          params: {
            event_id: this.eventId
          }
        })
        if (data?.data) {
          this.flowsList = data?.data
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingFlows = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.trainigFlowsTables{
  display: flex;
  flex-direction: column;
  .trainingFlowsTableItem:not(:last-child){
    margin-bottom: 30px;
  }
  &__wrapper{
    overflow: auto;
  }
}
</style>
