var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _vm.loading
        ? _c("CSpinner", { attrs: { color: "primary", size: "lg" } })
        : _c(
            "div",
            { staticClass: "trainingMaterials pb-3" },
            [
              _c(
                "CCard",
                {
                  staticClass: "trainingMaterials__block",
                  attrs: { color: "second-card-bg" },
                },
                [
                  _vm.isAdmin
                    ? _c("CInput", {
                        staticClass: "pl-0",
                        attrs: {
                          label: _vm.$t("lk.training.tg_group_url"),
                          type: "text",
                        },
                        model: {
                          value: _vm.course.tg_group_url,
                          callback: function ($$v) {
                            _vm.$set(_vm.course, "tg_group_url", $$v)
                          },
                          expression: "course.tg_group_url",
                        },
                      })
                    : _vm._e(),
                  _vm.isAdmin
                    ? _c("CInput", {
                        staticClass: "pl-0",
                        attrs: {
                          label: _vm.$t("lk.training.route_check"),
                          disabled: true,
                          type: "text",
                          value: _vm.route_check
                            ? "POST " + _vm.route_check
                            : "",
                        },
                      })
                    : _vm._e(),
                  _vm.isAdmin
                    ? _c("CInput", {
                        staticClass: "pl-0",
                        attrs: {
                          label: _vm.$t("lk.training.route_approved"),
                          disabled: true,
                          type: "text",
                          value: _vm.route_check
                            ? "PUT " + _vm.route_approved
                            : "",
                        },
                      })
                    : _vm._e(),
                  _vm.isAdmin && _vm.course.managers
                    ? _c(
                        "div",
                        { staticClass: "trainingPreview__managers" },
                        [
                          _c(
                            "h3",
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("lk.training.managersTraining")
                                  ) +
                                  " "
                              ),
                              _c(
                                "CLoadingButtonCustom",
                                {
                                  attrs: { color: "primary" },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.showManagerModal = true
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("lk.training.addManager")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._l(
                            _vm.course.managers,
                            function (manager, managerI) {
                              return _c(
                                "div",
                                { staticStyle: { margin: "1rem" } },
                                [
                                  _vm._v(" " + _vm._s(manager.title) + " "),
                                  _c(
                                    "CButton",
                                    {
                                      staticClass:
                                        "btn-sm shadow-none inline ml-3",
                                      attrs: { color: "outline-danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.course.managers.splice(
                                            managerI,
                                            1
                                          )
                                        },
                                      },
                                    },
                                    [_c("CIcon", { attrs: { name: "cil-x" } })],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "trainingPreview__authors" }, [
                    _c(
                      "h3",
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lk.training.authorTraining")) +
                            " "
                        ),
                        _c(
                          "CLoadingButtonCustom",
                          {
                            attrs: { color: "primary" },
                            nativeOn: {
                              click: function ($event) {
                                _vm.showAuthorModal = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.course.author
                                    ? _vm.$t("lk.training.replaceAuthor")
                                    : _vm.$t("lk.training.addAuthor")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.course.author
                      ? _c(
                          "div",
                          {
                            staticClass: "d-flex align-items-center",
                            staticStyle: { margin: "1rem" },
                          },
                          [
                            _vm.course.author
                              ? _c("small-author", {
                                  staticClass: "trainingMaterials__item-author",
                                  attrs: { author: _vm.course.author },
                                })
                              : _vm._e(),
                            _c(
                              "CButton",
                              {
                                staticClass: "btn-sm shadow-none inline ml-3",
                                attrs: { color: "outline-danger" },
                                on: {
                                  click: function ($event) {
                                    _vm.course.author = null
                                  },
                                },
                              },
                              [_c("CIcon", { attrs: { name: "cil-x" } })],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c(
                    "CLoadingButtonCustom",
                    {
                      staticClass: "cbtn mt-4 trainingMaterials__btn",
                      attrs: {
                        color: "primary",
                        loading: _vm.loadBtn,
                        disabled: _vm.loadBtn,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.editPreviewData.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("lk.training.btnSaveCourse")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c("ManagerModal", {
        attrs: { show: _vm.showManagerModal, eventId: _vm.eventId },
        on: {
          close: function ($event) {
            _vm.showManagerModal = false
          },
          success: _vm.handleUpdateManagerSuccess,
          "update:show": function ($event) {
            _vm.showManagerModal = $event
          },
        },
      }),
      _c("AuthorModal", {
        attrs: { show: _vm.showAuthorModal, eventId: _vm.eventId },
        on: {
          close: function ($event) {
            _vm.showAuthorModal = false
          },
          success: function ($event) {
            _vm.course.author = $event
          },
          "update:show": function ($event) {
            _vm.showAuthorModal = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }